import classes from "./ChangePasswordPopup.module.scss";
import {MdClose} from "react-icons/md";
import {createGradient} from "shared/constants";
import {GoKey} from "react-icons/go";
import {FaLock} from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from 'store/actions';
import {RiEyeFill, RiEyeOffFill} from 'react-icons/ri';
import {showToast} from "../../../../../common/utils";

const ChangePasswordPopup = (props) => {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [reEnterPassword, setReEnterPassword] = React.useState('');
  const [isOldPasswordShown, setIsOldPasswordShown] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isReEnterPasswordShown, setIsReEnterPasswordShown] = useState(false);




  const onClickHandler = () => {
    if (formIsValid()) {
      props.onChangePassword(oldPassword, newPassword, showToast);
      props.onClose();
    }
  }

  const formIsValid = () => {
    if (oldPassword === '') {
      showToast('Current Password cannot be empty', props.color);
      return false;
    } else if (newPassword === '') {
      showToast('New Password cannot be empty', props.color);
      return false;
    } else if (newPassword.length < 8) {
      showToast('Password should have at least 8 characters', props.color);
      return false;
    } else if (!/\d/.test(newPassword) || !/[a-zA-Z]/.test(newPassword)) {
      showToast('Password should include both letters and numbers', props.color);
      return false;
    } else if (newPassword !== reEnterPassword) {
      showToast('Passwords do not match', props.color);
      return false;
    }
    return true;
  }


  const viewOldPasswordOnClickHandler = () => {
    setIsOldPasswordShown(!isOldPasswordShown);
  };

  const viewNewPasswordOnClickHandler = () => {
    setIsNewPasswordShown(!isNewPasswordShown);
  };

  const viewReEnterPasswordOnClickHandler = () => {
    setIsReEnterPasswordShown(!isReEnterPasswordShown);
  };

  return (
    <div className={classes.ChangePasswordPopup}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdClose color="#C5C5C5" size={20}/>
        </div>
      </div>
      <p className={classes.Title}>Change Password</p>

      <div
        style={{
          background: `linear-gradient(142deg, 
          ${props.color} 0%, 
          ${createGradient(props.color, 80)} 
          100%)`
        }}
        className={classes.InputWrapper}>
        <div>
          <div className={classes.IconContainer}>
            <FaLock size={16}/>
          </div>
          <input
            placeholder="Old Password"
            inputMode="text"
            type={isOldPasswordShown ? 'text' : 'password'}
            value={oldPassword}
            onChange={e => setOldPassword(e.target.value)}
          />
          <div
            className={classes.ViewPassword}
            onClick={viewOldPasswordOnClickHandler}
          >
            {isOldPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
          </div>
        </div>
      </div>

      <div
        style={{
          background: `linear-gradient(142deg, 
          ${props.color} 0%, 
          ${createGradient(props.color, 80)} 
          100%)`
        }}
        className={classes.InputWrapper}
      >
        <div>
          <div className={classes.IconContainer}>
            <GoKey size={13}/>
          </div>
          <input
            placeholder="New Password"
            inputMode="text"
            type={isNewPasswordShown ? 'text' : 'password'}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
          <div
            className={classes.ViewPassword}
            onClick={viewNewPasswordOnClickHandler}
          >
            {isNewPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
          </div>
        </div>
      </div>

      <div
        style={{
          background: `linear-gradient(142deg, 
        ${props.color} 0%, 
        ${createGradient(props.color, 80)} 
        100%)`
        }}
        className={classes.InputWrapper}
      >
        <div>
          <div className={classes.IconContainer}>
            <GoKey size={13}/>
          </div>
          <input
            placeholder="Re-enter Password"
            inputMode="text"
            type={isReEnterPasswordShown ? 'text' : 'password'}
            value={reEnterPassword}
            onChange={e => setReEnterPassword(e.target.value)}
          />
          <div
            className={classes.ViewPassword}
            onClick={viewReEnterPasswordOnClickHandler}
          >
            {isReEnterPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
          </div>
        </div>
      </div>

      {props.loading ? (
        <div className={classes.SpinnerContainer}>
          <ClipLoader color={props.color} loading={true} size={20}/>
        </div>
      ) : (
        <button
          style={{
            background: `linear-gradient(91.3deg, 
          ${props.color} 1.61%, 
          ${createGradient(props.color, 50)} 
          100%)`
          }}
          className={classes.SaveButton}
          onClick={onClickHandler}
        >
          Save
        </button>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.account.loading,
    color: state.home.themeColor,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onChangePassword: (oldPassword, newPassword, showToast) =>
      dispatch(actions.changePassword(oldPassword, newPassword, showToast)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopup);
