import React, {useRef} from 'react';
import classes from './ShareableLink.module.scss';
import Header from './Header/Header';
import {RiArrowGoForwardLine} from 'react-icons/ri';
import {MdAdd, MdOutlineContentCopy, MdOutlineModeEditOutline, MdOutlineShoppingCart} from 'react-icons/md';
import {useNavigate, useOutletContext} from 'react-router-dom';
import BackgroundTop from '../../../../assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from '../../../../assets/images/createProfile/backgroundBottom.png';
import LinkedProductCard from "./LinkedProductsCard/LinkedProductCard";
import {connect} from 'react-redux';
import copy from 'copy-to-clipboard';
import {toast} from 'react-toastify';
import {adjustColorBrightness, createGradient} from "../../../../shared/constants";

const ShareableLink = (props) => {
  const outletContext = useOutletContext();
  const buttonRef = useRef(null);
  const linkText = process.env.REACT_APP_SHAREABLE_VIEW_URL;
  const navigate = useNavigate();

  const copyToClipboard = () => {
    const link = linkText + props.user;
    copy(link);
    toast('Copied to clipboard!', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    })
  }

  const onNavigate = path => {
    navigate(path);
  };

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {
        textAlign: 'center',
        background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`
      }
    });
  };

  return (
    <div className={classes.ShareableLink}>
      <Header toggleMenu={outletContext.toggleMenu}/>
      <div style={{backgroundColor: props.color}} className={classes.LeftContainer}>
        <div className={classes.LeftInnerContainer}>
          <img src={BackgroundTop} alt="background top"/>
          <img src={BackgroundBottom} alt="background bottom"/>
          <p>Simply Copy & Share</p>
          <p>
            Share these links among others so that
            <br/>
            they can view your profile
          </p>
        </div>
        <div style={{background: createGradient(adjustColorBrightness(props.color, 90), 40)}}
             className={classes.LinkListItemWrapper}>
          <div className={classes.LinkListItem}>
            <div className={classes.LinkContainer}>
              <div className={classes.Link}>
                <p>Shareable Link</p>
                <p style={{color: props.color}}>
                  {linkText}<span>{props.user}</span>
                </p>
              </div>
            </div>

            <div>
              <div className={classes.ButtonContainer}>
                <div>
                  <div ref={buttonRef} onClick={copyToClipboard} className={classes.Button}>
                    <MdOutlineContentCopy size={17} color={props.color}/>
                    <p>Copy Link</p>
                  </div>

                  <div className={classes.Button} onClick={() => window.open(linkText + props.user, '_blank')}>
                    <RiArrowGoForwardLine size={17} color={props.color}/>
                    <p>Preview</p>
                  </div>
                </div>
                <div className={classes.EditButton} onClick={() => {
                  onNavigate('/home/account')
                }}>
                  <MdOutlineModeEditOutline size={17} color={props.color}/>
                  <p>Edit</p>
                </div>
                <div className={classes.EditButtonSecondary}>
                  <MdOutlineModeEditOutline size={17} color={props.color}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.RightContainer}>
        <div className={classes.BuyButtonContainer}>
          <h1>Linked Products</h1>
          <div>
            <button
              onClick={() => {
                showToast('Please contact us at: sales@commercialprime.co.uk to link new products')
              }}
            >
              <MdAdd size={14} style={{color: props.color}} fontWeight={400}/>
              <p>Link New Products</p>
            </button>
            <button
              style={{backgroundColor: props.color}}
              onClick={() => window.open('https://commercialprime.co.uk', '_blank')}
            >
              <MdOutlineShoppingCart size={12}/>
              <p>Shop Now</p>
            </button>
          </div>
        </div>
        <div>
          {props.products.map(product => {
            return (
              <LinkedProductCard companyName={props.companyName} productLink={product.link} productName={product.name}
                                 color={props.color} key={product.link}/>
            )
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    products: state.products.products,
    color: state.home.status.themeColor,
    companyName: state.home.companyName
  };
};


export default connect(mapStateToProps)(ShareableLink);
