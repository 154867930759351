export const GET_STATUS_START = 'GET_STATUS_START';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAIL = 'GET_STATUS_FAIL';

export const GET_ALL_PROFILES_START = 'GET_ALL_PROFILES_START';
export const GET_ALL_PROFILES_SUCCESS = 'GET_ALL_PROFILES_SUCCESS';
export const GET_ALL_PROFILES_FAIL = 'GET_ALL_PROFILES_FAIL';

export const UPDATE_PROFILE_STATUS_START = 'UPDATE_PROFILE_STATUS_START';
export const UPDATE_PROFILE_STATUS_SUCCESS = 'UPDATE_PROFILE_STATUS_SUCCESS';
export const UPDATE_PROFILE_STATUS_FAIL = 'UPDATE_PROFILE_STATUS_FAIL';

export const UPDATE_HIGH_PRIVACY_MODE_START = 'UPDATE_HIGH_PRIVACY_MODE_START';
export const UPDATE_HIGH_PRIVACY_MODE_SUCCESS = 'UPDATE_HIGH_PRIVACY_MODE_SUCCESS';
export const UPDATE_HIGH_PRIVACY_MODE_FAIL = 'UPDATE_HIGH_PRIVACY_MODE_FAIL';

export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const CLEAR_HOME_DATA = 'CLEAR_HOME_DATA';
