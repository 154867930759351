import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import classes from './CreateOrEditProfile.module.scss';
import {RiArrowLeftSLine} from 'react-icons/ri';
import ProfileCard from './ProfileCard/ProfileCard';
import Logo from '../../assets/images/commercial prime-33.svg';
import BackgroundTop from '../../assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from '../../assets/images/createProfile/backgroundBottom.png';
import {IoImageOutline} from 'react-icons/io5';
import {useNavigate, useParams} from 'react-router-dom';
import AddFieldModal from './AddFieldModal/AddFieldModal';
import PrivacyModeDropdown from './VisibilityDropdown/PrivacyModeDropdown';
import * as actions from '../../store/actions';
import {useDropzone} from 'react-dropzone';
import {adjustColorBrightness, createGradient} from 'shared/constants';
import PreviewConfirmation from './PreviewConfirmation/PreviewConfirmation';
import Backdrop from '../UI/Backdrop/Backdrop';
import DeleteConfirmation from './DeleteConfirmation/DeleteConfirmation';
import ExitConfirmation from './ExitConfirmation/ExitConfirmation';
import imageCompression from 'browser-image-compression';
import ImageCropper from './ImageCropper/ImageCropper';
import {MdDelete, MdOutlineClose} from 'react-icons/md';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import PhoneInput from 'react-phone-input-2';
import {Spin, Tooltip} from 'antd';
import {IconPickerItem} from 'react-fa-icon-picker';
import {base64ToFile, getCountry, getPreSignedTempUrl, showToast} from '../../common/utils';
import {FaXTwitter} from 'react-icons/fa6';
import {
    PROFILE_DATA_FIELD_TYPE,
    PROFILE_DATA_FIELDS,
    PROFILE_DATA_PRIVACY_MODE,
    PROFILE_FIELD_LAYOUT_TYPE,
} from '../../common/enums';
import ChangeLayoutModal from './ChangeLayoutModal/ChangeLayoutModal';
import {IoMdCloudUpload} from 'react-icons/io';
import {getProfileData} from 'store/actions/home/home';
import {HttpStatusCode} from 'axios';

const CreateOrEditProfile = props => {
    const navigate = useNavigate();
    const {profileId} = useParams();
    const dispatch = useDispatch();

    const [loadingMessage, setLoadingMessage] = useState('Loading');
    const [isLoading, setIsLoading] = useState(false);

    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [showChangeLayoutModal, setShowChangeLayoutModal] = useState(false);
    const [showPreviewProfileConfirmation, setShowPreviewProfileConfirmation] =
        useState(false);
    const [isProfileDarkMode, setIsProfileDarkMode] = useState(true);
    const [showDeleteProfileConfirmation, setShowDeleteProfileConfirmation] =
        useState(false);
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);
    const [previewConfirmationMessage, setPreviewConfirmationMessage] =
        useState(null);
    const [adjustColor, setAdjustColor] = useState(null);
    const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
    const [deleteButtonIsClicked, setDeleteButtonIsClicked] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [profileFields, setProfileFields] = useState(
        Object.entries(PROFILE_DATA_FIELDS).map(
            ([
                 key,
                 {name, icon, type, privacyMode, privacyModeToggleAllowed, layout},
             ]) => ({
                id: null,
                name: name,
                icon: icon,
                type: type,
                privacyMode: privacyMode,
                privacyModeToggleAllowed: privacyModeToggleAllowed,
                value: '',
                layout: layout,
            }),
        ),
    );
    const [profilePicture, setProfilePicture] = useState(undefined);
    const [profilePicturePreview, setProfilePicturePreview] = useState(undefined);

    const leftProfileFields = [
        PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        PROFILE_DATA_FIELDS.FIRST_NAME.name,
        PROFILE_DATA_FIELDS.LAST_NAME.name,
        PROFILE_DATA_FIELDS.COMPANY.name,
        PROFILE_DATA_FIELDS.TITLE.name,
        PROFILE_DATA_FIELDS.CONTACT_NUMBER.name,
        PROFILE_DATA_FIELDS.EMAIL.name,
        PROFILE_DATA_FIELDS.DESCRIPTION.name,
    ];

    React.useEffect(() => {
        setAdjustColor(adjustColorBrightness(props.themeColor, 60));
    }, []);

    useEffect(() => {
        if (props.mode === 'edit' && profileId) {
            setLoadingMessage('Loading Profile');
            setIsLoading(true);
            dispatch(getProfileData(profileId))
                .then(async profileData => {
                    const {profilePicture, filteredProfileFields} =
                        profileData.data.reduce(
                            (acc, field) => {
                                if (field.name === 'Profile Picture') {
                                    acc.profilePicture = field;
                                } else {
                                    acc.filteredProfileFields.push(field);
                                }
                                return acc;
                            },
                            {profilePicture: null, filteredProfileFields: []},
                        );

                    setProfileFields(filteredProfileFields);

                    let profilePictureUrl = profilePicture.value;
                    if (profilePictureUrl != null && !profilePictureUrl.includes("public")) {
                        profilePictureUrl = await getPreSignedTempUrl(profilePictureUrl)
                    }
                    setProfilePicturePreview(profilePictureUrl);
                })
                .catch(error => {
                    console.error('Error: ', error);
                    showToast('Error fetching profile data', props.themeColor);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.mode, profileId, dispatch]);

    // useEffect(() => {
    //     if (profileData && profileData.length > 0 ) {
    //         console.log("Profile data received:", profileData);
    //         cons
    //         setProfileFields(profileData);
    //     }
    // }, [profileData]);

    // const isEditMode = !!query.get('edit');
    // const isCreateMode = !!query.get('create');

    // useEffect(() => {
    //   props.onClearData();
    //   setAdjustColor(adjustColorBrightness(props.themeColor, 80));
    //   props.getCompanySettings(showToast);
    // }, []);

    // useEffect(() => {
    //   if (props.companySettings) {
    //     setIsReadOnly(
    //       (props.userRole === 'ROLE_CORPORATE_USER' ||
    //         props.userRole === 'ROLE_PLATFORM_USER') &&
    //         !props.companySettings.enableProfileSelfUpdate,
    //     );
    //   }
    // }, [props.companySettings]);

    // const onDrop = useCallback(acceptedFiles => {
    //     setImage(acceptedFiles[0]);
    // }, []);
    // //
    // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    //
    // const setImage = async file => {
    //     if (file.type.split('/')[0] !== 'compressedImage') {
    //         showToast('Please select a valid compressedImage');
    //         return;
    //     }
    // }

    const onAddProfileField = data => {
        const updatedProfileFields = [...profileFields];

        updatedProfileFields.push();

        setProfileFields([
            ...profileFields,
            {
                id: null,
                name: data.fieldName,
                icon: data.fieldIcon,
                type: data.fieldType,
                value: '',
                privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
                privacyModeToggleAllowed: true,
                isCustom: true,
            },
        ]);
    };

    const onUpdateProfileField = updatedField => {
        setProfileFields(prevFields =>
            prevFields.map(field =>
                field.name === updatedField.name ? {...field, ...updatedField} : field,
            ),
        );
    };

    const onUpdateProfileFields = updatedFields => {
        setProfileFields(prevFields =>
            prevFields.map(field => {
                const updatedField = updatedFields.find(f => f.name === field.name);
                return updatedField ? {...field, ...updatedField} : field;
            }),
        );
    };

    const onRemoveProfileField = fieldName => {
        setProfileFields(profileFields.filter(field => field.name !== fieldName));
    };
    //
    //   try {
    //     const compressedImage = await imageCompression(file, {
    //       maxSizeMB: 0.1,
    //       maxWidthOrHeight: 1920,
    //       useWebWorker: true,
    //     });
    //
    //     const base64Result = await getBase64(compressedImage);
    //     props.onChangeProfilePicture(base64Result);
    //     setShowCropper(true);
    //   } catch (error) {
    //     console.error('Image select error:', error);
    //   }
    // };
    //
    // const getBase64 = file => {
    //   return new Promise(resolve => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //   });
    // };

    const [compressedImage, setCompressedImage] = useState(null);
    const [showCropper, setShowCropper] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        compressImage(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const compressImage = async file => {
        if (file.type.split('/')[0] !== 'image') {
            showToast('Please select a valid image to compress', props.themeColor);
            return;
        }

        try {
            const compressedImage = await imageCompression(file, {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1280,
                useWebWorker: true,
            });

            const base64Result = await getBase64(compressedImage);
            setCompressedImage(base64Result);
            setShowCropper(true);
        } catch (error) {
            console.error('Image compression error: ', props.themeColor);
            showToast('Error compressing image', props.themeColor);
        }
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const renderProfilePictureForDesktop = () => (
        <div
            className={classes.DropArea}
            {...(!isReadOnly ? getRootProps() : [])}
            style={{
                borderColor: isDragActive ? '#dbb659' : '#424242',
                cursor: isReadOnly ? 'no-drop' : 'pointer',
            }}
        >
            {' '}
            {!isReadOnly && <input {...getInputProps()} accept="image/*"/>}
            <div>
                <div className={classes.ImageContainer}>
                    {profilePicturePreview && !isReadOnly && (
                        <div
                            className={classes.ClearButton}
                            onClick={e => {
                                setCompressedImage(null);
                                setProfilePicture(null)
                                setProfilePicturePreview(null);
                                e.stopPropagation();
                            }}
                        >
                            <MdOutlineClose size={18} color="#C5C5C5"/>
                        </div>
                    )}
                    {profilePicturePreview ? (
                        <img src={profilePicturePreview} alt="Profile Picture"/>
                    ) : (
                        <div className={classes.IconContainer}>
                            <IoMdCloudUpload size={72} color="#919191"/>
                        </div>
                    )}
                </div>

                {!isReadOnly && (
                    <div style={{marginLeft: '32px'}}>
                        <p>
                            Drag & drop or click to select
                            <br/>
                            your profile picture
                        </p>
                    </div>
                )}
            </div>
        </div>
    );

    const renderProfilePictureForMobile = () => (
        <div className={classes.MobileProfilePictureContainer}>
            <div
                style={{
                    background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(
                        adjustColor,
                        50,
                    )} 100%)`,
                }}
                className={classes.ProfilePicture}
                {...getRootProps()}
            >
                <div>
                    {profilePicturePreview && !isReadOnly && (
                        <div
                            className={classes.ClearButton}
                            onClick={e => {
                                setCompressedImage(null);
                                setProfilePicture(null)
                                setProfilePicturePreview(null);
                                e.stopPropagation();
                            }}
                        >
                            <MdOutlineClose size={16} color="#C5C5C5"/>
                        </div>
                    )}
                    {profilePicturePreview ? (
                        <img src={profilePicturePreview} alt="Profile"/>
                    ) : (
                        <IoImageOutline size={32} color="#929292"/>
                    )}
                </div>
            </div>
            <p>Profile Picture</p>
        </div>
    );

    const REQUIRED_FIELDS = [
        PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        PROFILE_DATA_FIELDS.FIRST_NAME.name,
        PROFILE_DATA_FIELDS.LAST_NAME.name,
        PROFILE_DATA_FIELDS.CONTACT_NUMBER.name,
    ];

    const validateFields = fields => {
        const errors = [];

        fields.forEach(field => {
            // Check for required fields
            if (REQUIRED_FIELDS.includes(field.name) && !field.value) {
                errors.push(`${field.name} is required`);
            }

            // Validate first name and last name to contain only letters
            if (
                (field.name === PROFILE_DATA_FIELDS.FIRST_NAME.name ||
                    field.name === PROFILE_DATA_FIELDS.LAST_NAME.name) &&
                field.value
            ) {
                const nameRegex = /^[A-Za-z]+$/; // Allows letters only
                if (!nameRegex.test(field.value)) {
                    errors.push(`${field.name} must contain only letters`);
                }
            }

            // Validate links
            if (
                field.type === PROFILE_DATA_FIELD_TYPE.LINK &&
                field.value &&
                !field.value.startsWith('https://')
            ) {
                errors.push(`${field.name} link must start with https://`);
            }

            // Validate email addresses
            if (
                field.type === PROFILE_DATA_FIELD_TYPE.EMAIL &&
                field.value &&
                !/\S+@\S+\.\S+/.test(field.value)
            ) {
                errors.push(`${field.name} must be a valid email address`);
            }

            // Validate phone numbers (international format without leading +)
            if (
                field.type === PROFILE_DATA_FIELD_TYPE.CONTACT_NUMBER &&
                field.value
            ) {
                const phoneRegex = /^[1-9][0-9]{6,14}$/; // Allows 7 to 15 digits, no leading 0 or +
                if (!phoneRegex.test(field.value)) {
                    errors.push(`${field.name} must be a valid phone number`);
                }
            }
        });

        return errors;
    };

    const uploadProfilePicture = async () => {
        let file;
        if (profilePicture.includes(';base64')) {
            file = base64ToFile(profilePicture, 'profile-picture');
        } else {
            file = profilePicture;
        }

        try {
            const uploadResult = await props.onUploadFile(file);
            return uploadResult.data;
        } catch (error) {
            showToast('Failed to upload profile picture', props.themeColor);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const onDeleteHandler = async () => {
        try {
            setLoadingMessage("Deleting profile");
            setIsLoading(true);
            await props.onDeleteProfile(profileId);
            setShowDeleteProfileConfirmation(false)

            showToast("Profile deleted successfully", props.themeColor)
            props.onClearData();

            setTimeout(() => navigate(-1), 500);

        } catch (error) {

            showToast("Failed to delete profile", props.themeColor)
        } finally {
            setIsLoading(false);
        }
    }


    const onSaveHandler = async () => {
        const errors = validateFields(profileFields);

        if (errors.length > 0) {
            errors.forEach(error => showToast(error, props.themeColor));
            return;
        }

        let profilePictureUploadResult = null;
        if (profilePicture) {
            setLoadingMessage('Uploading Profile Picture');
            setIsLoading(true);
            profilePictureUploadResult = await uploadProfilePicture();

            if (profilePictureUploadResult === null) {
                return;
            }
        }

        const data = profileFields.filter(
            field => field.name !== PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        );
        data.push({
            id: null,
            name: 'Profile Picture',
            icon: 'FaImage',
            type: PROFILE_DATA_FIELD_TYPE.IMAGE,
            value: profilePictureUploadResult ? profilePictureUploadResult.url : null,
            privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
            privacyModeToggleAllowed: false,
            layout: PROFILE_FIELD_LAYOUT_TYPE.LIST,
        });

        const requestData = {
            name: profileFields.find(
                field => field.name === PROFILE_DATA_FIELDS.PROFILE_NAME.name,
            )?.value,
            darkMode: isProfileDarkMode,
            data,
        };

        try {
            setLoadingMessage('Saving Profile');
            setIsLoading(true);

            let result = null;

            if (props.mode === 'edit') {
                requestData.id = profileId;
                result = await props.onUpdateProfile(requestData);
            } else {
                result = await props.onSaveProfile(requestData);
            }

            if (result?.status === HttpStatusCode.Ok) {
                showToast('Profile saved successfully', props.themeColor);
                props.onClearData();

                setTimeout(() => navigate(-1), 500);
            }
        } catch (error) {
            showToast('Failed to save profile', props.themeColor);
        } finally {
            setIsLoading(false);
        }
    };

    // const onDeleteHandler = successCallback => {
    //   props.onDeleteProfile(
    //     isEditMode ? +query.get('edit') : null,
    //     successCallback,
    //     showToast,
    //   );
    //   setShowDeleteProfileConfirmation(false);
    //   props.onClearData();
    // };

    // const onDeleteConfirmationHandler = () => {
    //   const totalProfileCount = props.profiles.length;
    //
    //   if (totalProfileCount > 1) {
    //     const toActiveProfile = props.profiles.find(profile => profile.active);
    //     if (query.get('edit') === toActiveProfile.id.toString()) {
    //       const activeProfile = props.profiles.find(profile => !profile.active);
    //       props.onChangeActiveProfile(activeProfile.id, null, () => {
    //         onDeleteHandler(() => {
    //           showToast('Successfully deleted!');
    //           setTimeout(() => navigate('/home'), 1000);
    //         });
    //       });
    //     } else {
    //       onDeleteHandler(() => {
    //         showToast('Successfully deleted!');
    //         setTimeout(() => navigate('/home'), 1000);
    //       });
    //     }
    //   } else {
    //     onDeleteHandler();
    //   }
    // };

    // const onPreviewConfirmationHandler = () => {
    //   onSaveHandler(() => {
    //     setShowPreviewProfileConfirmation(false);
    //     props.onChangeActiveProfile(+query.get('edit'), null, () => {
    //       window.open(
    //         process.env.REACT_APP_SHAREABLE_VIEW_URL + '@' + props.products[0].link,
    //         '_blank',
    //       );
    //     });
    //   });
    // };

    const renderField = (field, index, fieldDisabled = false) => {
        let inputElement;
        switch (field.type) {
            case PROFILE_DATA_FIELD_TYPE.CONTACT_NUMBER:
                inputElement = (
                    <PhoneInput
                        dropdownStyle={{background: '#242424', color: 'white'}}
                        inputStyle={{
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                            background: 'transparent',
                            width: '100%',
                            border: 'none',
                        }}
                        country={getCountry()}
                        value={field.value}
                        placeholder={field.name}
                        onChange={value =>
                            onUpdateProfileField({name: field.name, value: value})
                        }
                        disabled={fieldDisabled || isReadOnly}
                    />
                );
                break;
            case PROFILE_DATA_FIELD_TYPE.TEXTAREA:
                inputElement = (
                    <textarea
                        placeholder={field.name}
                        rows={5}
                        style={{
                            height: '100%',
                            display: 'flex',
                            overflow: 'hidden',
                        }}
                        value={field.value}
                        onChange={e =>
                            onUpdateProfileField({name: field.name, value: e.target.value})
                        }
                        disabled={fieldDisabled || isReadOnly}
                    />
                );
                break;
            case PROFILE_DATA_FIELD_TYPE.LINK:
                inputElement = (
                    <input
                        placeholder={field.name}
                        type="text"
                        value={field.value}
                        onChange={e =>
                            onUpdateProfileField({name: field.name, value: e.target.value})
                        }
                        //onChange={e => linkChange(e, index, true)}
                        //onKeyDown={e => linkChange(e, index, true)}
                        disabled={fieldDisabled || isReadOnly}
                    />
                );
                break;
            default:
                inputElement = (
                    <input
                        placeholder={field.name}
                        type={field.type.toLowerCase() || 'text'}
                        value={field.value}
                        onChange={e =>
                            onUpdateProfileField({name: field.name, value: e.target.value})
                        }
                        disabled={fieldDisabled || isReadOnly}
                    />
                );
        }

        return (
            <div
                key={field.name}
                className={classes.InputWrapper}
                style={{
                    background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(
                        adjustColor,
                        80,
                    )} 100%)`,
                }}
            >
                <div
                    style={{
                        height:
                            field.type === PROFILE_DATA_FIELD_TYPE.TEXTAREA
                                ? '120px'
                                : '56px',
                        alignItems:
                            field.type === PROFILE_DATA_FIELD_TYPE.TEXTAREA
                                ? 'start'
                                : 'center',
                        gap: '8px',
                    }}
                >
                    {field.icon !== undefined &&
                        field.icon !== null &&
                        (field.icon === 'FaTwitter' || field.icon === 'FaXTwitter' ? (
                            <FaXTwitter color="#919191" size={21}/>
                        ) : (
                            <IconPickerItem icon={field.icon} color="#919191" size={17}/>
                        ))}
                    <Tooltip
                        title={
                            field.type === PROFILE_DATA_FIELD_TYPE.LINK
                                ? `${field.name} Link`
                                : field.name
                        }
                    >
                        {inputElement}
                    </Tooltip>

                    <PrivacyModeDropdown
                        privacyMode={field.privacyMode}
                        onChange={value =>
                            onUpdateProfileField({name: field.name, privacyMode: value})
                        }
                        disabled={isReadOnly || !field.privacyModeToggleAllowed}
                    />
                </div>
                {field.isCustom && (
                    <Tooltip title={'Remove field'}>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                padding: 10,
                            }}
                            onClick={() => onRemoveProfileField(field.name)}
                        >
                            <MdDelete size={20} color={'#919191'}/>
                        </button>
                    </Tooltip>
                )}
            </div>
        );
    };
    //
    // const linkChange = (e, index) => {
    //
    //     if (e.keyCode === 8) {
    //         props.onChangeProfileFieldValue(e.target.value, index);
    //     } else {
    //         const value = e.target.value.trim();
    //         const isCompleteURL = value.startsWith('https://');
    //
    //         const isBackspaceKeyPressed =
    //             e.nativeEvent.inputType === 'deleteContentBackward';
    //         if (isBackspaceKeyPressed && !value) {
    //             props.onChangeProfileFieldValue('', index);
    //         } else {
    //             let updatedValue = value;
    //
    //             if (!isCompleteURL) {
    //                 updatedValue = `https://facebook.com/${value}`;
    //             }
    //
    //             props.onChangeProfileFieldValue(updatedValue, index);
    //         }
    //     }
    // };

    return (
        <div className={classes.CreateProfile}>
            {/*<input*/}
            {/*    type="file"*/}
            {/*    id="inpProfilePicture"*/}
            {/*    style={{display: 'none'}}*/}
            {/*    onChange={e => compressImage(e.target.files[0])}*/}
            {/*/>*/}

            {showPreviewProfileConfirmation && (
                <Backdrop onClick={() => setShowPreviewProfileConfirmation(false)}>
                    <PreviewConfirmation
                        color={props.themeColor}
                        onClose={() => setShowPreviewProfileConfirmation(false)}
                        onConfirmation={() => alert('Preview')}
                        confirmationMessage={previewConfirmationMessage}
                    />
                </Backdrop>
            )}
            {showDeleteProfileConfirmation && (
                <Backdrop onClick={() => setShowDeleteProfileConfirmation(false)}>
                    <DeleteConfirmation
                        color={props.themeColor}
                        onClose={() => setShowDeleteProfileConfirmation(false)}
                        onConfirmation={onDeleteHandler}
                    />
                </Backdrop>
            )}
            {showExitConfirmation && (
                <Backdrop onClick={() => setShowExitConfirmation(false)}>
                    <ExitConfirmation
                        color={props.themeColor}
                        onClose={() => setShowExitConfirmation(false)}
                        onConfirmation={() => {
                            navigate(-1);
                        }}
                    />
                </Backdrop>
            )}
            {showCropper && (
                <ImageCropper
                    profilePicture={compressedImage}
                    onChangeProfilePicture={image => {
                        setCompressedImage(null);
                        setProfilePicture(image);
                        setProfilePicturePreview(image)
                    }}
                    onClose={() => setShowCropper(false)}
                    themeColor={props.themeColor}
                />
            )}

            {isLoading && (
                <Spin
                    tip={loadingMessage}
                    size={'large'}
                    style={{color: props.themeColor}}
                    fullscreen
                />
            )}

            {/* Header */}
            <div className={classes.Header}>
                <div className={classes.BackButtonWrapper}>
                    <div
                        className={classes.BackButton}
                        onClick={() => setShowExitConfirmation(true)}
                    >
                        <RiArrowLeftSLine size={32}/>
                    </div>
                </div>
                <div className={classes.Title}>
                    <span>{props.mode === 'edit' ? 'Edit' : 'Create'} Profile</span>
                </div>
                {!isReadOnly && (
                    <div className={classes.ActionButtonsContainer}>
                        <button
                            className={classes.ChangeLayoutButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => setShowChangeLayoutModal(true)}
                        >
                            Change Layout
                        </button>
                        {props.mode === 'edit' && (
                            <button
                                className={classes.DeleteButton}
                                disabled={deleteButtonIsClicked}
                                onClick={() => {
                                    if (profileId != props.status.activeProfileId) setShowDeleteProfileConfirmation(true);
                                    else showToast("Active profile cannot be deleted, change active profile and try again", props.themeColor);
                                }}
                            >
                                Delete
                            </button>
                        )}


                        <button
                            style={{
                                background: `linear-gradient(91.3deg, ${
                                    props.themeColor
                                } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                            }}
                            className={classes.SaveButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => onSaveHandler()}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>

            {/* Profile Card Preview */}
            <ProfileCard
                fullName={`${
                    profileFields.find(
                        f => f.name === PROFILE_DATA_FIELDS.FIRST_NAME.name,
                    ).value
                } ${
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.LAST_NAME.name)
                        .value
                }`}
                title={
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.TITLE.name)
                        .value
                }
                company={
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.COMPANY.name)
                        .value
                }
                hidePreviewButton={props.mode !== 'edit'}
                color={props.themeColor}
                userRole={props.userRole}
                showConfirmation={() => {
                    const activeProfile = props.profiles.find(profile => profile.active);
                    if (props.profileId === activeProfile.id) {
                        setPreviewConfirmationMessage(
                            "The profile you're editing will be saved with the current changes",
                        );
                    } else {
                        setPreviewConfirmationMessage(
                            "The profile you're editing will be saved and set as the active profile",
                        );
                    }
                    setShowPreviewProfileConfirmation(true);
                }}
            />

            {/* Main Content */}
            <div className={classes.Body}>
                <div
                    style={{backgroundColor: props.themeColor}}
                    className={classes.TopContainer}
                >
                    <img src={Logo} alt="Commercial Prime"/>
                    <img src={BackgroundTop} alt="Background Top"/>
                    <img src={BackgroundBottom} alt="Background Bottom"/>
                </div>

                <div className={classes.CreateProfileFormContainer}>
                    <div className={classes.CreateProfileForm}>
                        {/* Profile Picture Upload Area */}
                        {renderProfilePictureForDesktop()}
                        {renderProfilePictureForMobile()}

                        {!props.companyExists && (
                            <div className={classes.ThemeButton}>
                                <DarkModeSwitch
                                    checked={isProfileDarkMode}
                                    onChange={() => {
                                        const result = !isProfileDarkMode;
                                        setIsProfileDarkMode(result);
                                        showToast(
                                            `Current Profile set to ${
                                                result ? 'dark' : 'light'
                                            } mode`,
                                            props.themeColor,
                                        );
                                    }}
                                    size={25}
                                    sunColor={'white'}
                                />
                            </div>
                        )}

                        {/* Form Fields */}
                        <div className={classes.InputContainer}>
                            <div>
                                {/*{*/}
                                {/*    renderField({*/}
                                {/*        type: PROFILE_DATA_FIELD_TYPE.TEXT,*/}
                                {/*        name: "Profile Name",*/}
                                {/*        icon: "FaFolderMinus",*/}
                                {/*        privacyMode: PROFILE_DATA_PRIVACY_MODE.PRIVATE*/}
                                {/*    }, 0)*/}
                                {/*}*/}
                                {profileFields
                                    .filter(field => leftProfileFields.includes(field.name))
                                    .map((field, index) => renderField(field, index))}
                            </div>
                            <div>
                                {profileFields
                                    .filter(field => !leftProfileFields.includes(field.name))
                                    .map((field, index) => renderField(field, index))}
                                {!isReadOnly && (
                                    <div
                                        className={classes.AddFieldButton}
                                        onClick={() => setShowAddFieldModal(true)}
                                    >
                                        <p>+ Add Field</p>
                                    </div>
                                )}
                                {!isReadOnly && (
                                    <>
                                        <button
                                            className={classes.ChangeLayoutButtonMobile}
                                            disabled={saveButtonIsClicked}
                                            onClick={() => setShowChangeLayoutModal(true)}
                                        >
                                            Change Layout
                                        </button>
                                        <button
                                            style={{
                                                background: `linear-gradient(91.3deg, ${
                                                    props.themeColor
                                                } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                                            }}
                                            className={classes.SaveButtonMobile}
                                            disabled={saveButtonIsClicked}
                                            onClick={() => onSaveHandler()}
                                        >
                                            Save
                                        </button>
                                        {props.mode === 'edit' && (
                                            <button
                                                className={classes.DeleteButtonMobile}
                                                onClick={() => {
                                                    if (profileId != props.status.activeProfileId) setShowDeleteProfileConfirmation(true);
                                                    else showToast("Active profile cannot be deleted, change active profile and try again", props.themeColor);
                                                }}>
                                                Delete
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {showAddFieldModal && (
                        <AddFieldModal
                            setShowModal={setShowAddFieldModal}
                            themeColor={props.themeColor}
                            profileFields={profileFields}
                            onAddProfileField={onAddProfileField}
                        />
                    )}

                    {showChangeLayoutModal && (
                        <ChangeLayoutModal
                            setShowModal={setShowChangeLayoutModal}
                            themeColor={props.themeColor}
                            profileFields={profileFields.filter(
                                field =>
                                    field.privacyMode !== PROFILE_DATA_PRIVACY_MODE.PRIVATE,
                            )}
                            onUpdateProfileFields={onUpdateProfileFields}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        user: state.auth.user,
        status: state.home.status,
        profiles: state.home.profiles,
        themeColor: state.home.status.themeColor,
        products: state.products.products,
        companyExists: state.home.companyExists,
        companyName: state.home.companyName,
        userRole: state.auth.userRole,
        companySettings: state.settings.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCompanySettings: showToast =>
            dispatch(actions.getCompanySettings(showToast)),
        onClearData: () => dispatch(actions.clearHomeData()),
        onUploadFile: file => dispatch(actions.uploadFile(file)),
        onSaveProfile: requestData => dispatch(actions.saveProfile(requestData)),
        onUpdateProfile: (requestData, successCallback) =>
            dispatch(actions.updateProfile(requestData, successCallback)),
        onCreateTeamProfile: (requestData, successCallback) =>
            dispatch(actions.createTeamProfile(requestData, successCallback)),
        onChangeActiveProfile: (profileId, callback) =>
            dispatch(actions.changeActiveProfile(profileId, callback)),
        onDeleteProfile: (profileId, successCallback, showToast) =>
            dispatch(actions.deleteProfile(profileId, successCallback, showToast)),
        onGetCompanyDetails: () => dispatch(actions.getTeamsCompanyDetails()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateOrEditProfile);
