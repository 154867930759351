import classes from './Sidebar.module.scss';
import {FiUsers} from 'react-icons/fi';
import {BsFillCreditCardFill} from 'react-icons/bs';
import {BiBuildings} from 'react-icons/bi';
import {CgMenuLeft, CgMenuRight} from 'react-icons/cg';
import {useLocation, useNavigate} from "react-router-dom";
import {MdOutlineBrandingWatermark, MdOutlineSettings} from "react-icons/md";

const SideBar = ({ open, setOpen, color }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickExpand = () => {
    setOpen(!open);
  }

  const onNavigate = path => {
    navigate(path);
  }

  return (
    <section
        style={{display: location.pathname === 'create-profile' ? "none" : null}}
      className={open ? classes.SidebarWrapper : classes.SidebarWrapperSmall}
    >
      <div className={classes.Head}>
        <span>TEAMS</span>
        {open ?
          <CgMenuRight onClick={onClickExpand} className={classes.ToggleButton} />
          : <CgMenuLeft onClick={onClickExpand} className={classes.ToggleButton} />
        }

      </div>
      <div
          className={location.pathname === 'users' ? classes.ItemSelected : classes.MenuItems}
          style={{background: location.pathname === 'users' ? color : ''}}
          onClick={() => onNavigate('users')}
      >
        <FiUsers />
        <span>Users</span>
      </div>
      <div
          className={location.pathname === 'profiles' ? classes.ItemSelected : classes.MenuItems}
          style={{background: location.pathname === 'profiles' ? color : ''}}
          onClick={() => onNavigate('profiles')}
      >
        <BsFillCreditCardFill />
        <span>Profiles</span>
      </div>
      <div
          className={location.pathname === 'company-details' ? classes.ItemSelected : classes.MenuItems}
          style={{background: location.pathname === 'company-details' ? color : ''}}
          onClick={() => onNavigate('company-details')}
      >
        <BiBuildings />
        <span>Company Details</span>
      </div>
      <div
          className={location.pathname === 'branding' ? classes.ItemSelected : classes.MenuItems}
          style={{background: location.pathname === 'branding' ? color : ''}}
          onClick={() => onNavigate('branding')}
      >
        <MdOutlineBrandingWatermark />
        <span>Branding</span>
      </div>
      <div
          className={location.pathname === 'settings' ? classes.ItemSelected : classes.MenuItems}
          style={{background: location.pathname === 'settings' ? color : ''}}
          onClick={() => onNavigate('settings')}
      >
        <MdOutlineSettings />
        <span>Settings</span>
      </div>
    </section>
  );
};

export default SideBar;

