import classes from './Branding.module.scss';
import {MdOutlineClose} from "react-icons/md";
import UploadImage from "../../../../../../assets/images/upload.png";
import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import imageCompression from "browser-image-compression";
import {toast} from "react-toastify";
import {createGradient} from "../../../../../../shared/constants";
import {connect} from "react-redux";
import {BsPlus} from "react-icons/bs";
import {ClipLoader} from "react-spinners";
import * as actions from "../../../../../../store/actions";
import {getTeamsCompanyDetails} from "../../../../../../store/actions";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import ColorPicker from "../../../Account/ColorPicker/ColorPicker";
import ImageCropper from "../../../../../CreateProfile/ImageCropper/ImageCropper";


const Branding = (props) => {
  const [logo, setLogo] = useState('');
  const [cropperVisible, setCropperVisibility] = useState(false);
  const [colorPickerIsOpen, setColorPickerIsOpen] = React.useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [socialIcons , setSocialIcons] = useState(true);

  useEffect(()=>{
    const fetchData = async () => {
      const data = await props.onGetCompanyDetails();
      setLogo(data.brandingLogo);
      setDarkMode(data.setting.darkMode);
      setSocialIcons(data.setting.socialMediaLock);
    }
    fetchData();
  },[])

  const showToast = message => {
    toast(message, {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: classes.CustomToast,
      style: {background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 35)} 100%)`}
    });
  };


  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = '';

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const setImage = file => {
    if (file.type.split('/')[0] !== 'image') {
      showToast('Select only an image ');
    } else {
      imageCompression(file, {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      })
        .then(compressedImage => {
          return getBase64(compressedImage);
        })
        .then(result => {
          setLogo(result)
          props.onChangeBrandingLogo(result);
          setCropperVisibility(true);
        })
        .catch((e) => console.log('Image select error'+ e));
    }
  };

  const onDrop = React.useCallback(acceptedFiles => {
    setImage(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const colorSetOne = [
    '#CD3838',
    '#51BC4E',
    '#00BFFF',
    '#E556E0',
    '#03E5B5',
    '#6236FF',
    '#FFA07A',
    '#CCFF00',
  ];

  const colorSetTwo = [
    '#D9D9D9',
    '#A181F1',
    '#FF8C00',
    '#E301A0',
    '#87cfeb',
    '#A12DB4',
    '#E47676',
    '',
  ];

  const onHandleChangeBackgroundColor = (newColor) => {
    props.onChangeThemeColor(newColor);
  };

  const setCustomColor = (color) => {
    props.onChangeThemeColor(color);
  }

  let result = '';

  const onUpdateHandler = async () => {
    if (props.logo.includes(';base64')) {
      const base64String = props.logo;
      const byteCharacters = atob(base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: 'image/png'});
      const file = new File([blob], 'image.jpg', {type: 'image/png'});

      result = await props.onUploadCompanyLogo(file);
    } else {
      result = logo;
    }

    props.onUpdateTeamsBranding({
      brandingLogo: result,
      darkMode: darkMode,
      socialMediaLock: socialIcons,
      themeColor: props.color,
    }, showToast)
  }

  return (
    <section className={classes.Branding}>
      {colorPickerIsOpen ? (
        <Backdrop onClick={() => setColorPickerIsOpen(false)}>
          <ColorPicker
            onClose={() => setColorPickerIsOpen(false)}
            color={props.color}
            changeColor={setCustomColor}
          />
        </Backdrop>
      ) : null}
      {cropperVisible ? (
        <Backdrop onClick={() => setCropperVisibility(false)}>
          <ImageCropper onClose={() => setCropperVisibility(false)}/>
        </Backdrop>
      ) : null}

      <p className={classes.Title}>Branding</p>
      <div className={classes.MiddleContainer}>
        <div
          className={classes.ImageUpload}
          {...getRootProps()}
          style={{borderColor: isDragActive ? '#dbb659' : '#424242'}}
        >
          <input {...getInputProps()} />

          <div>
            <div className={classes.ImageContainer}>
              {logo === '' || logo === null ? null : (
                <div
                  className={classes.ClearButton}
                  onClick={e => {
                    props.onChangeBrandingLogo('');
                    setLogo('')
                    e.stopPropagation();
                  }}
                >
                  <MdOutlineClose size={16} color="#C5C5C5"/>
                </div>
              )}
              <img
                src={
                  logo === '' || logo === null
                    ? UploadImage
                    : props.logo.includes(';base64')
                      ? props.logo
                      : logo
                }
                alt={"Upload"}
              />

            </div>
          </div>
          <p>
            Upload or drag & drop your Company Icon here
          </p>
          <button
            style={{
              color: props.color,
              borderColor: props.color
            }}
          >
            Browse Files
          </button>
        </div>

        <div className={classes.InputBox}>
          <div className={classes.Detail}>
            <p className={classes.Label}>Theme</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <p
                  className={classes.AccountStatus}>{darkMode ? "Dark Mode" : "Light Mode"}</p>
                <div
                  className={classes.StatusSwitch}
                  onClick={() => setDarkMode(!darkMode)}
                  style={{justifyContent: darkMode ? "flex-end" : "flex-start"}}
                >
                  <div className={classes.StatusNob}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.Detail}>
            <p className={classes.Label}>Social Icon</p>
            <div style={{background: props.color}} className={classes.InputWrapper}>
              <div className={classes.Input}>
                <p
                  className={classes.AccountStatus}>{socialIcons ? "Show" : "Hide"}</p>
                <div
                  className={classes.StatusSwitch}
                  onClick={()=>setSocialIcons(!socialIcons)}
                  style={{justifyContent: socialIcons ? "flex-end" : "flex-start"}}
                >
                  <div className={classes.StatusNob}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <div className={classes.ColorSection}>
          <div className={classes.PaletteContainer}>
            <div className={classes.DefaultColor}>
              <p>Account Theme</p>
              <p
                onClick={() => onHandleChangeBackgroundColor("#DBB659")}
              >Reset color
              </p>
            </div>
            <div className={classes.ColorPalette}>
              {[...colorSetOne, ...colorSetTwo].map((color, index) =>
                color === '' ? (
                  <div onClick={() => setColorPickerIsOpen(true)} className={classes.ColorPlus} key={`plus-${index}`}>
                    <BsPlus size={16} color="white"/>
                  </div>
                ) : (
                  <div
                    className={classes.Color}
                    style={{backgroundColor: color}}
                    key={`color-${index}`}
                    onClick={() => onHandleChangeBackgroundColor(color)}
                  />
                ),
              )}
            </div>
          </div>

          <div style={{background: props.color}} className={classes.MobilePaletteContainer}>
            <div className={classes.MobilePalette}>
              <div className={classes.DefaultColorSection}>
                <p>Account Theme</p>
                <p
                  onClick={() => onHandleChangeBackgroundColor('#DBB659')}
                >
                  Reset color
                </p>
              </div>
              <div className={classes.MobileColorPalette}>
                <div>
                  {colorSetOne.map((color, index) =>
                    color === '' ? (
                      <div key={`color-plus-${index}`}
                           className={classes.ColorPlus}
                           onClick={() => setColorPickerIsOpen(true)}
                      >
                        <BsPlus size={16} color="white"/>
                      </div>
                    ) : (
                      <div
                        key={`color-${color}-${index}`}
                        className={classes.Color}
                        style={{backgroundColor: color}}
                        onClick={() => onHandleChangeBackgroundColor(color)}
                      />
                    )
                  )}

                </div>
                <div>
                  {colorSetTwo.map((color, index) =>
                    color === '' ? (
                      <div key={`color-plus-${index}`} className={classes.ColorPlus}
                           onClick={() => setColorPickerIsOpen(true)}>
                        <BsPlus size={16} color="white"/>
                      </div>
                    ) : (
                      <div
                        key={`color-${color}-${index}`}
                        className={classes.Color}
                        style={{backgroundColor: color}}
                        onClick={() => onHandleChangeBackgroundColor(color)}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              style={{
                background:
                  `linear-gradient(91.3deg, 
                ${props.color} 
                1.61%, 
                ${createGradient(props.color, 50)} 
                100%)`
              }}
              className={classes.UpdateDetailsBtn}
              onClick={onUpdateHandler}
            >
              {props.loading === true ? (
                <div className={classes.SpinnerContainer}>
                  <ClipLoader color={'#dbb659'} loading={true} size={22}/>
                </div>
              ) : (
                <p>Update Details</p>
              )
              }</button>
          </div>
        </div>
      </div>

    </section>
  );
}

const mapStateToProps = state => {
  return {
    color: state.home.status.themeColor,
    loading: state.teams.loading,
    logo:state.teams.brandingLogo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeThemeColor: (color) => dispatch(actions.changeThemeColor(color)),
    onChangeBrandingLogo: image =>
      dispatch(actions.changeBrandingLogo(image)),
    onGetCompanyDetails: () => dispatch(getTeamsCompanyDetails()),
    onUploadCompanyLogo: logo => dispatch(actions.uploadCompanyLogo(logo)),
    onUpdateTeamsBranding : (data, showToast) => dispatch(actions.updateTeamsBrandingDetailsSuccess(data, showToast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branding);
