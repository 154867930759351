import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {getSignedUrl} from '@aws-sdk/s3-request-presigner';
import ct from 'countries-and-timezones';
import {toast} from 'react-toastify';
import classes from '../pages/Dashbord/DashboardPage.module.scss';
import {createGradient} from '../shared/constants';
import {PROFILE_DATA_PRIVACY_MODE} from './enums';

export const getPreSignedTempUrl = async link => {
  const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    endpoint: process.env.REACT_APP_AWS_ENDPOINT,
  });

  const key = link.split('/').pop();

  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: key,
    ResponseContentDisposition: 'inline',
  };

  const command = new GetObjectCommand(params);

  // Generate a signed URL valid for 600 seconds
  return await getSignedUrl(s3, command, {expiresIn: 600});
};

export const getCountry = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const obj = ct.getCountryForTimezone(tz);
  return obj.id.toLowerCase();
};

export const showToast = (message, color = '#DBB659') => {
  toast(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: classes.CustomToast,
    bodyStyle: {
      padding: 6,
    },
    style: {
      padding: 12,
      background: `linear-gradient(142deg, ${color} 0%, ${createGradient(
        color,
          50,
      )} 100%)`,
    },
  });
};

export const getLabelForPrivacyMode = privacyMode => {
  switch (privacyMode) {
    case PROFILE_DATA_PRIVACY_MODE.PRIVATE:
      return 'Private';
    case PROFILE_DATA_PRIVACY_MODE.PUBLIC_BLOCK_EXPORT:
      return 'Public - Block Export';
    case PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT:
      return 'Public - Allow Export';
    default:
      return '';
  }
};

export const base64ToFile = (base64String, defaultFileName = 'file') => {
  // Extract the MIME type and base64 data
  const matches = base64String.match(/^data:(image\/\w+);base64,/);
  if (!matches) {
    throw new Error('Invalid BASE64 string format');
  }

  const mimeType = matches[1];
  const extension = mimeType.split('/')[1];
  const byteString = atob(base64String.split(',')[1]);

  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: mimeType});

  return new File([blob], `${defaultFileName}.${extension}`, {type: mimeType});
};
