import classes from "./LinkedProductsCard.module.scss";
import {MdOutlineDelete, MdOutlineModeEditOutline} from "react-icons/md";
import {RiArrowGoForwardLine} from "react-icons/ri";
import React from "react";
import {useNavigate} from "react-router-dom";

const LinkedProductCard = (props) => {
  const navigate = useNavigate();
  const onNavigate = path => {
    navigate(path);
  };

  return (
    <div className={classes.Card}>
      <h4>{props.companyName === '' ? 'Commercial Prime' : props.companyName} {props.productName}</h4>
      <div className={classes.EditAndDeleteButtons}>
        <p style={{color: props.color}}>Product Link: {props.productLink}</p>
        <div>
          <button onClick={() => {
            onNavigate('/home/account')
          }}><MdOutlineModeEditOutline size={14} color={props.color}/></button>
          <button><MdOutlineDelete size={14} color={props.color}/></button>
        </div>
      </div>
      <div className={classes.PreviewButtonSection}>
        <button className={classes.Button}
                onClick={() => window.open(process.env.REACT_APP_SHAREABLE_VIEW_URL + '@' + props.productLink, '_blank')}>
          <RiArrowGoForwardLine size={17} color={props.color}/>
          <p>Preview</p>
        </button>
      </div>
    </div>
  )
}

export default LinkedProductCard
