import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';
import {jwtDecode} from "jwt-decode";

const signInStart = () => {
  return {
    type: actionTypes.SIGN_IN_START,
  };
};

const signInSuccess = payload => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
    payload,
  };
};

const signInFail = () => {
  return {
    type: actionTypes.SIGN_IN_FAIL,
  };
};

export const signIn = (tenantName, username, password, showToast) => {
  return dispatch => {
    dispatch(signInStart());
    axios
      .post('/login', {tenantName, username, password})
      .then(res => {
        const data = JSON.parse(localStorage.getItem('prime'));
        let decode = jwtDecode(res.data.token);
        const authData = {
          token: res.data.token,
          user: decode.sub,
          userRole: decode.role,
        };

        dispatch(signInSuccess(authData));
        localStorage.setItem('prime', JSON.stringify(authData));

        if (!data || !data.token) {
          showToast('Login successful, welcome back!');
        }
      })
      .catch(err => {
        console.log(err);
        if (!err.response) {
          showToast('Check your internet connectivity!');
        } else if (err.response.data.error === "Unauthorized") {
          showToast('Incorrect username or password!');
        } else {
          showToast('Something went wrong!');
        }
        dispatch(signInFail());
      });
  };
};


export const tryAutoSignIn = () => {
  return dispatch => {
    const authData = JSON.parse(localStorage.getItem('prime'));
    if (!authData) {
      dispatch(signOut());
    } else {
      dispatch(signInSuccess(authData));
    }
  };
};

export const signOut = () => {
  localStorage.removeItem('prime');
  return {
    type: actionTypes.SIGN_OUT,
  };
};

export const isUserNameExist = (username) => {
  return async dispatch => {
    try {
      const response = await axios.post('/api/user-service/exits-username?userName=' + username);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export const fetchUserDetailsByToken = (token) => {
  return async dispatch => {
    try {
      const response = await axios.get(`/api/user-service/user-details-by-token?token=${token}`);
      console.log(response.data)
      return response.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };
};


const registerStart = () => {
  return {
    type: actionTypes.REGISTER_START,
  };
};

const registerEnd = () => {
  return {
    type: actionTypes.REGISTER_END,
  };
};

export const register = (
  firstName,
  accountType,
  lastName,
  companyId,
  contactNo,
  email,
  password,
  registrationToken,
  tenantName,
  userName,
  successCallback,
  errorCallback,
  showToast,
) => {
  return dispatch => {
    dispatch(registerStart());
    axios
      .post('/api/user-service/register', {
        firstName,
        accountType,
        lastName,
        companyId,
        contactNo,
        email,
        password,
        registrationToken,
        tenantName,
        userName,
      })
      .then(() => {
        dispatch(registerEnd());
        successCallback();
      })
      .catch(err => {
        errorCallback();
        if (!err.response) {
          showToast('Check your internet connectivity');
        } else if (err.response.data.errorCode === 'INVALID_TOKEN') {
          showToast('Invalid registration token')
        } else if (err.response.data.errorCode === 'DUPLICATE_USERNAME') {
          showToast('Username already exists');
        } else if (err.response.data.errorCode === 'DUPLICATE_EMAIL') {
          showToast('Email already exists');
        } else if (err.response.data.errorCode === 'THIS_EMAIL_CANNOT_BE_USED_WITH_THIS_TOKEN') {
          showToast('This email cannot be used with this token');
        } else if (err.response.data.errorCode === 'REG_CONFIRM_EMAIL_FAILED') {
          showToast('Failed to send registration confirmation email');
        } else {
          showToast('Something went wrong');
        }
        dispatch(registerEnd());
      });
  };
};

export const sendEmailForForgotPassword = (email) => {
  return async () => {
    try {
      return await axios.post('/api/user-service/email-for-forget-password', {
        email,
        tenantName: "CommercialPrime",
      })
    } catch (error) {
      if (error.response.data.errorCode === 'ACCOUNT_NOT_REGISTERED') {
        return 'ACCOUNT_NOT_REGISTERED';
      } else {
        return false;
      }
    }
  };
};

export const verifyOTP = (object) => {
  return async () => {
    try {
      const value = await axios.post('/api/user-service/otp-for-forget-password', object);
      return value.data;
    } catch (err) {
      return false;
    }
  };
};

export const changeForgotPassword = (object) => {
  return async () => {
    try {
      const value = await axios.post('/api/user-service/forget-password', object);
      return value.data;
    } catch (err) {
      return false;
    }
  };
};

