import classes from './Users.module.scss';
import {GoPlus} from 'react-icons/go';
import {BiSearchAlt} from 'react-icons/bi';
import {RiShieldUserFill} from 'react-icons/ri';
import {MdArrowDropDown} from 'react-icons/md';
import {Button, Input, Space, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import button from "../../../../../UI/Button/Button";
import {connect} from "react-redux";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import NewUser from "./NewUser/NewUser";
import ViewUser from "./ViewUser/VIewUser";
import * as actions from '../../../../../../store/actions';
import ClipLoader from "react-spinners/ClipLoader";
import ProfileLimitReached from "../../../../../UI/ProfileLimitReached/ProfileLimitReached";

const Users = (props) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [newUserIsOpen, setNewUserIsOpen] = useState(false);
    const [viewUserIsOpen, setViewUserIsOpen] = useState(false);
    const [userData, setUserData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userLimitIsReached, setUserLimitIsReached] = useState(false);
    const searchInput = useRef(null);

    const filteredData = props.users.filter((user) =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phoneNumber.includes(searchTerm)
    );

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    useEffect(() => {
        if (users.length === 0) {
            props.onGetAllCompanyUsers();
        }
    }, []);

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    padding: 8,

                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        borderColor: props.color,
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<BiSearchAlt/>}
                        size="small"
                        style={{
                            width: 90,
                            background: props.color,
                            gap: 5
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        style={{color: 'black'}}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                        style={{color: 'black'}}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <MdArrowDropDown
                size={20}
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (

                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const itemRender = (_, type, originalElement) => {
        if (type === 'next') {
            return <a className={classes.PaginationNext}>Next</a>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Contact Number',
            dataIndex: 'contact',
            key: 'contact',
            ...getColumnSearchProps('contact'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'QR Code',
            dataIndex: 'qrCode',
            key: 'qrCode',
            ...getColumnSearchProps('qrCode'),
            width: '15%',
            render: (text) => (
                <h1
                    style={{
                        margin: 0,
                        fontSize: 13,
                        color: text === 'Not Assigned' ? '#5d8f87' : '#BBBBBB',
                        textDecoration: "underline"
                    }}
                >
                    {text}
                </h1>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {text: 'Active', value: 'Active'},
                {text: 'Deactive', value: 'Deactive'},
                {text: 'Pending', value: 'Pending'},
            ],
            onFilter: (value, record) => {
                return record.status === value
            },
            width: '10%',
            render: text => (
                <h1 style={{
                    margin: 0,
                    fontSize: 13,
                    color: text === 'Active' ? '#999999' : (text === 'Pending' ? '#59B4DB' : '#DBB659')
                }}>
                    {text}
                </h1>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, recode) => (
                <button
                    onClick={() => {
                        setUserData(recode.userData)
                        setViewUserIsOpen(true)
                    }}
                    className={classes.ButtonInsideTable}
                >
                    View
                </button>),
            align: 'center',
            fixed: 'right',
            width: '15%',
        },
    ];

    const users = filteredData.map((user, index) => {
        const isAdmin = user.role === 'ROLE_COMPANY_ADMIN';

        return {
            key: index + 1,
            name: (
                <span style={{display: 'flex', alignItems: 'center'}}>
        {user.firstName} {user.lastName}
                    {isAdmin && <RiShieldUserFill size={16} style={{color: props.color, marginLeft: 6}}/>}
      </span>
            ),
            contact: user.phoneNumber || '',
            email: user.email || '',
            qrCode: user.products === null ? 'Not Assigned' : (user.products?.link || ''),
            status: user.status ? user.status.charAt(0).toUpperCase() + user.status.slice(1).toLowerCase() : '',
            actions: '',
            userData: user,
        };
    });

    return (
        <section className={classes.UsersWrapper}>
            {newUserIsOpen ? (
                <Backdrop onClick={() => setNewUserIsOpen(false)}>
                    <NewUser
                        onClose={() => {
                            setNewUserIsOpen(false);
                        }}
                        color={props.color}
                    />
                </Backdrop>
            ) : null}
            {viewUserIsOpen ? (
                <Backdrop onClick={() => setViewUserIsOpen(false)}>
                    <ViewUser
                        data={userData}
                        onClose={() => {
                            setViewUserIsOpen(false);
                        }}
                        color={props.color}
                    />
                </Backdrop>
            ) : null}

            {userLimitIsReached ? (
                <Backdrop onClick={() => setUserLimitIsReached(false)}>
                    <ProfileLimitReached
                        color={props.color}
                        onClose={() => setUserLimitIsReached(false)}
                    />
                </Backdrop>
            ) : null}
            <div className={classes.TopContainer}>
                <div className={classes.UserCount}>
                    <h1>Users</h1>
                    <span>{props.users.length}</span>
                </div>
                <div className={classes.SearchContainer}>
                    <button onClick={() => {
                        if (props.users.length === 0) {
                            setNewUserIsOpen(true)
                        } else if (props?.users[0].users <= props.users.length) {
                            setUserLimitIsReached(true)
                        } else {
                            setNewUserIsOpen(true)
                        }
                    }}>
                        <GoPlus/>
                        Create New User
                    </button>
                    <div>
                        <BiSearchAlt/>
                        <input type="text" placeholder="Search contacts..." value={searchTerm}
                               onChange={event => setSearchTerm(event.target.value)}/>
                    </div>
                </div>
            </div>

            <div className={classes.TableContainer}>
                {props.loading ? (
                    <div className={classes.SpinnerContainer}>
                        <ClipLoader color={props.color} loading={true} size={60}/>
                    </div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={users.toSorted((a, b) => a.userData.role === 'ROLE_COMPANY_ADMIN' ? -1 : 1)
                            /*filteredData.reverse().map((user, index) => ({
                              key: index + 1,
                              name: (
                                <span style={{display:'flex',alignItems:'center'}}>
                                  {user.firstName} {user.lastName}
                                  {user.role === 'ROLE_COMPANY_ADMIN' && <RiShieldUserFill size={16} style={{color:props.color, marginLeft:6}}/>}
                                </span>
                              ),
                              contacts: user.phoneNumber,
                              email: user.email,
                              qrCode: user.products === null ? "Not Assigned" : user.products?.products,
                              status: user.status.charAt(0).toUpperCase() + user.status.slice(1).toLowerCase(),
                              actions: '',
                              userData: user,
                            }))*/
                        }
                        pagination={{
                            pageSize: 9,
                            itemRender: itemRender
                        }}
                        size="small"
                        scroll={{
                            x: 750,
                        }}
                    />
                )}

            </div>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        color: state.home.status.themeColor,
        users: state.teams.users,
        loading: state.teams.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetAllCompanyUsers: () => dispatch(actions.getAllUsers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
