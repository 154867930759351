import * as actionTypes from "../actions/teams/actionTypes";

const initialState = {
  expanded: false,
  users: [],
  loading: false,
  dLoading: false,
  profiles: [],
  brandingLogo: '',
};

const setExpand = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};


const changeBrandingLogo = (state, action) => {
  return {
    ...state,
    brandingLogo: action.payload,
  };
};

const getAllUsersStart = (state, action) => {
  return {...state, loading: true}
};

const setAllUsers = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateUsersStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateUsersSuccess = (state, action) => {
  const updatedUser = action.payload;
  const updatedUsers = state.users.map((user) =>
    user.id === updatedUser.id ? updatedUser : user
  );
  return { ...state, users: updatedUsers };
}

const updateUsersEnd = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const getAllProfilesStart = (state, action) => {
  return {...state, loading: true}
};

const getAllProfilesSuccess = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const getAllProfilesEnd = (state, action) => {
  return {...state, loading: false}
}

const updateCompanyDetailsStart = (state, action) => {
  return {...state, loading: true}
};

const updateCompanyDetailsEnd = (state, action) => {
  return {...state, loading: false}
};

const updateTeamsBrandingDetailsStart = (state, action) => {
  return {...state,loading: true };
}

const updateTeamsBrandingDetailsEnd = (state, action) => {
  return {...state, loading: false};
};

const addNewTeamUserStart = (state, action) => {
  return {...state, loading: true}
};

const addNewTeamUserSuccess = (state, action) => {
  const newUser = action.payload;
  const qrLink = newUser.products && newUser.products.length > 0 
    ? newUser.products[0].link 
    : '';
  
  
  const userWithQrLink = {
    ...newUser,
    qrLink: qrLink
  };

  return {
    ...state,
    loading: false,
    users: [...state.users, action.payload]
  };
}

const addNewTeamUserEnd = (state, action) => {
  return {...state, loading: false}
};

const deleteTeamUserStart = (state, action) => {
  return {...state, dLoading: true};
};

const deleteTeamUserEnd = (state, action) => {
  return {...state, dLoading: false};
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_EXPAND:
      return setExpand(state, { payload: { expanded: !state.expanded } });
    case actionTypes.GET_ALL_COMPANY_USERS:
      return setAllUsers(state, action);
    case actionTypes.CHANGE_BRANDING_IMAGE:
      return changeBrandingLogo(state, action);
    case actionTypes.GET_ALL_COMPANY_USERS_START:
        return getAllUsersStart(state, action);
    case actionTypes.UPDATE_COMPANY_USER_START:
      return updateUsersStart(state, action);
    case actionTypes.UPDATE_COMPANY_USER_SUCCESS:
      return updateUsersSuccess(state, action);
    case actionTypes.UPDATE_COMPANY_USER_END:
      return updateUsersEnd(state, action);
    case actionTypes.GET_ALL_PROFILES_START:
      return getAllProfilesStart(state, action);
    case actionTypes.GET_ALL_TEAM_USERS_PROFILES:
      return getAllProfilesSuccess(state, action);
    case actionTypes.GET_ALL_PROFILES_END:
      return getAllProfilesEnd(state, action);
    case actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_START:
      return updateCompanyDetailsStart(state, action);
    case actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_END:
      return updateCompanyDetailsEnd(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_START:
      return addNewTeamUserStart(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_SUCCESS:
      return addNewTeamUserSuccess(state, action);
    case actionTypes.ADD_NEW_TEAM_USER_END:
      return addNewTeamUserEnd(state, action);
    case actionTypes.DELETE_TEAM_USER_START:
      return deleteTeamUserStart(state, action);
    case actionTypes.DELETE_TEAM_USER_END:
      return deleteTeamUserEnd(state, action);
    case actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_START:
      return updateTeamsBrandingDetailsStart(state, action);
    case actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_END:
      return updateTeamsBrandingDetailsEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
