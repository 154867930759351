import * as actionTypes from "./actionTypes";
import axios from "../../../common/axios";
import {signOut} from "../auth/auth";

export const changeExpandState = () => {
  return {
    type: actionTypes.CHANGE_EXPAND,
  };
};

const getAllUsersStart = () => {
  return {
    type: actionTypes.GET_ALL_COMPANY_USERS_START,
  };
};

export const setAllUsers = payload => {
  return {
    type: actionTypes.GET_ALL_COMPANY_USERS,
    payload
  };
};

export const getAllUsers = () => {
  return dispatch => {
    dispatch(getAllUsersStart());
    axios.get('/api/company-service/get-all-teams-users',).then(res => {
      const users = {
        users: res.data,
        loading: false,
      };
      dispatch(setAllUsers(users));
    });
  };
};

const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_COMPANY_USER_START,
  };
};

export const updateUserSuccess = (updatedUser) => ({
  type: actionTypes.UPDATE_COMPANY_USER_SUCCESS,
  payload: updatedUser,
});


export const updateTeamsUserSuccess = (data, showToast, onClose, loggedInUser, pastData) => {
  return (dispatch) => {
    dispatch(updateUserStart());
    axios
      .post("/api/company-service/update-teams-user", data)
      .then((response) => {
        if (response.status === 200) {
          showToast("Successfully updated!");
          const updatedUser = response.data;
          dispatch(updateUserEnd());
          dispatch(updateUserSuccess(updatedUser));
          onClose();

          if (loggedInUser.username === pastData.username && loggedInUser.role !== data.role) {
            setTimeout(() => {
              showToast("User role of current user changed, " +
                "please login again!");
              dispatch(signOut());
            }, 500);
          }
        }
      })
      .catch((e) => {
        if (e.response.data.errorCode === "PRODUCT_NOT_FOUND") {
          showToast("Invalid QR Code!");
        } else if (e.response.data.errorCode === "INVALID_PRODUCT_REGISTRATION") {
          showToast("QR Code already registered!");
        } else if (e.response.data.errorCode === "COMPANY_SHOULD_HAVE_AT_LEAST_ONE_ACTIVE_ADMIN_ACCOUNT") {
          showToast("Company should have at least one active admin account");
        } else {
          showToast("Something went wrong!");
        }
        dispatch(updateUserEnd());
      });

  };
};


const updateUserEnd = () => {
  return {
    type: actionTypes.UPDATE_COMPANY_USER_END,
  };
};

const getAllProfilesStart = () => {
  return {
    type: actionTypes.GET_ALL_PROFILES_START,
  };
}

const getAllProfilesSuccess = payload => {
  return {
    type: actionTypes.GET_ALL_TEAM_USERS_PROFILES,
    payload,
  };
};

const getAllProfilesEnd = () => {
  return {
    type: actionTypes.GET_ALL_PROFILES_END,
  };
};

export const getAllTeamsUserProfiles = () => {
  return dispatch => {
    dispatch(getAllProfilesStart());
    axios
      .get('api/company-service/get-all-teams-profiles')
      .then(res => {
        const profileData = {
          profiles: res.data,
          vcardStatus: res.data[0].accountStatus,
          themeColor: res.data[0].color,
          loading: false,
        }
        dispatch(getAllProfilesSuccess(profileData));
      })
      .catch((e) => {
        dispatch(getAllProfilesEnd());
        console.log(e)
      });
  };
};


export const getTeamsCompanyDetails = () => {
  return async () => {
    try {
      const response = await axios.get('/api/company-service/get-teams-company');
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
};

const updateTeamsCompanyStart = () => {
  return {
    type: actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_START,
  };
};

export const updateTeamsCompanyDetails = (data, showToast) => {
  return dispatch => {
    dispatch(updateTeamsCompanyStart());
    axios
      .post('/api/company-service/update-company-teams-detail', data)
      .then(() => {
        dispatch(updateTeamsCompanyEnd());
        showToast('Successfully Updated!')
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateTeamsCompanyEnd());
        showToast('Something went wrong!')
      })
  };
};

const updateTeamsCompanyEnd = () => {
  return {
    type: actionTypes.UPDATE_TEAMS_COMPANY_DETAILS_END,
  };
};

const updateTeamsBrandingDetailsStart = () => {
  return {
    type: actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_START,
  };
};

export const updateTeamsBrandingDetailsSuccess = (data, showToast) => {
  return dispatch => {
    dispatch(updateTeamsBrandingDetailsStart());
    axios
      .post('/api/company-service/update-branding-teams-detail', data)
      .then(() => {
        dispatch(updateTeamsBrandingDetailsEnd());
        showToast('Successfully Updated!')
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateTeamsBrandingDetailsEnd());
        showToast('Something went wrong!')
      })
  };
};

export const changeBrandingLogo = payload => {
  return {
    type: actionTypes.CHANGE_BRANDING_IMAGE,
    payload,
  };
};

const updateTeamsBrandingDetailsEnd = () => {
  return {
    type: actionTypes.UPDATE_TEAMS_BRANDING_DETAILS_END,
  };
};

export const uploadCompanyLogo = image => {
  return async () => {
    const token = JSON.parse(localStorage.getItem('prime'));

    const myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      'Bearer ' + token.token,
    );

    const formData = new FormData();
    formData.append('file', image);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/company-service/uploadFile`,
        requestOptions,
      );
      return await response.text();
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  };
};

const addNewTeamsUserStart = () => {
  return {
    type: actionTypes.ADD_NEW_TEAM_USER_START,
  };
};

const addNewTeamsUserSuccess = (user) => {
  return {
    type: actionTypes.ADD_NEW_TEAM_USER_SUCCESS,
    payload: user,
  };
};

const addNewTeamsUserEnd = () => {
  return {
    type: actionTypes.ADD_NEW_TEAM_USER_END
  };
}

export const addTeamsUser = (details, showToast, close) => {
  return dispatch => {
    dispatch(addNewTeamsUserStart());
    axios
      .post('/api/company-service/add-teams-user', details)
      .then(res => {
        
        console.log('Success Response:', res.data); 
        const qrLink = res.data.products && res.data.products.length > 0 
          ? res.data.products[0].link 
          : '';
        
        
        const newUser = {
          ...res.data,
          qrLink: qrLink
        };



        dispatch(addNewTeamsUserSuccess(newUser));
        showToast("Successfully Added User!")
        close();
      })
      .catch((error) => {
        if (error.response.data.errorCode === "DUPLICATE_EMAIL") {
          showToast("Email already in use!");
        } else if (error.response.data.errorCode === "PRODUCT_NOT_FOUND") {
          showToast("Invalid QR Code!");
        } else if (error.response.data.errorCode === "INVALID_PRODUCT_REGISTRATION") {
          showToast("QR Code already registered!");
        } else if (error.response.data.errorMessage.includes("com.sun.mail.smtp.SMTPSendFailedException")) {
          showToast("Email is not authorized!");
        } else {
          showToast("Something went wrong!");
        }

        dispatch(addNewTeamsUserEnd());
      })
  };
};


const deleteTeamUserStart = () => {
  return {
    type: actionTypes.DELETE_TEAM_USER_START,
  };
}

export const deleteTeamUser = (id, showToast, commonClose) => {
  return dispatch => {
    dispatch(deleteTeamUserStart());
    axios
      .delete('/api/company-service/delete-teams-user?userId=' + id)
      .then(() => {
        showToast("Successfully Deleted!");
        dispatch(deleteTeamUserEnd());
        commonClose(true);
      })
      .catch((error) => {
        commonClose(false);
        dispatch(deleteTeamUserEnd());
        if (error.response.data.errorCode === 'CANNOT_DELETE_ADMIN_ACCOUNT') {
          showToast("Cannot delete admin account!");
        } else {
          showToast("Something went wrong!");
        }
      })
  }
};


const deleteTeamUserEnd = () => {
  return {
    type: actionTypes.DELETE_TEAM_USER_END,
  };
}


export const createTeamProfile = (requestData, successCallback) => {
  return dispatch => {
    axios
      .post('/api/company-service/create-teams-user-profile', requestData)
      .then(() => {
        successCallback();
      })
      .catch(err => console.log(err.response.data));
  };
};
