import React from 'react';
import classes from './PrimeMenu.module.scss';
import {MdKeyboardArrowRight, MdOutlineClose} from 'react-icons/md';
import AccountImage from '../../../assets/images/primeMenu/AccountsSVG.svg';
import LinkImage from '../../../assets/images/primeMenu/LinkSVG.svg';
import AnalyticsImage from '../../../assets/images/primeMenu/AnalyticsSVG.svg';
import ContactsImage from '../../../assets/images/primeMenu/ContactsSVG.svg';
import Teams from '../../../assets/images/primeMenu/Teams.svg';
import SVG from 'react-inlinesvg';
import {useNavigate} from 'react-router-dom';
import {FaPowerOff} from "react-icons/fa";
import Backdrop from '../../UI/Backdrop/Backdrop';
import LogOutConfirmation from "../../Dashboard/DashboardHeader/LogOutConfirmation/LogOutConfirmation";
import {adjustColorBrightness} from "../../../shared/constants";
import InstallPWAButton from "../PWAInstallButton/InstallPWAButton";

const PrimeMobileMenu = ({menuOpen, toggleMenu, color, role}) => {
  const styleClasses = [classes.PrimeMenu];
  const navigate = useNavigate();


  const [logoutConfirmationPopupVisible, setLogoutConfirmationPopupVisible] = React.useState(false);

  if (menuOpen) {
    styleClasses.push(classes.PrimeMenuOpen);
  }

  return (
    <>
      {menuOpen ? (
        <div className={classes.Backdrop} onClick={toggleMenu}></div>
      ) : null}

      {logoutConfirmationPopupVisible ? (
        <Backdrop onClick={() => setLogoutConfirmationPopupVisible(false)}>
          <LogOutConfirmation
            color={color}
            onClose={() => setLogoutConfirmationPopupVisible(false)}
          />
        </Backdrop>
      ) : null}

      <div className={styleClasses.join(' ')}>
        <div className={classes.MenuHeader}>
          <p>Prime Menu</p>

          <div className={classes.CloseButtonContainer} onClick={toggleMenu}>
            <MdOutlineClose size={22} color="#C5C5C5"/>
          </div>
        </div>

        <div className={classes.OptionsContainer}>
          <div className={classes.Options} style={{width: role === "ROLE_COMPANY_ADMIN" ? 240 : 270}}>
            <div className={classes.Items}>
              <div
                style={{
                  borderColor: adjustColorBrightness(color, 100),
                  width: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                  height: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                }}
                className={classes.Option}
                onClick={() => {
                  navigate('/home/analytics');
                  toggleMenu();
                }}
              >
                <SVG src={AnalyticsImage} alt="Analytics" fill={color}/>
                <p style={{color: color}}>Analytics</p>
              </div>
              <div
                style={{
                  borderColor: adjustColorBrightness(color, 100),
                  width: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                  height: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                }}
                className={classes.Option}
                onClick={() => {
                  navigate('/home/contacts');
                  toggleMenu();
                }}
              >
                <SVG src={ContactsImage} alt="Contacts" fill={color}/>
                <p style={{color: color}}>Contacts</p>
              </div>
            </div>
            <div className={classes.Items}>
              <div
                style={{
                  borderColor: adjustColorBrightness(color, 100),
                  width: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                  height: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                }}
                className={classes.Option}
                onClick={() => {
                  navigate('/home/link');
                  toggleMenu();
                }}
              >
                <SVG src={LinkImage} alt="Link" fill={color} width={32}/>
                <p style={{color: color}}>Link</p>
              </div>
              <div
                style={{
                  borderColor: adjustColorBrightness(color, 100),
                  width: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                  height: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                }}
                className={classes.Option}
                onClick={() => {
                  navigate('/home/account');
                  toggleMenu();
                }}
              >
                <SVG src={AccountImage} fill={color} width={37}/>
                <p style={{color: color}}>Account</p>
              </div>
            </div>
            {role === 'ROLE_COMPANY_ADMIN' &&
              <div className={classes.Items} style={{display: 'contents'}}>
                <div className={classes.TeamsContain}>
                  <div
                    style={{
                      borderColor: adjustColorBrightness(color, 100),
                      width: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                      height: role === 'ROLE_COMPANY_ADMIN' ? 100 : 115,
                    }}
                    className={classes.Option}
                    onClick={() => {
                      navigate('/home/teams');
                      toggleMenu();
                    }}
                  >
                    <SVG src={Teams} fill={color}/>
                    <p style={{color: color}}>Teams</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className={classes.LinkContainer} onClick={() => window.open('https://commercialprime.co.uk/', '_blank')}>
          <div>
            <p>Visit Commercial Prime Website</p>
            <MdKeyboardArrowRight color="#C7C7C7" size={18}/>
          </div>
        </div>
        <InstallPWAButton small={false} styles={classes.PWAButton}/>

        <div className={classes.LogOutButton}>
          <FaPowerOff
            style={{cursor: 'pointer'}}
            size={30}
            onClick={() => {
              toggleMenu()
              setLogoutConfirmationPopupVisible(true)
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PrimeMobileMenu;
