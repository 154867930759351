import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';

export const toggleExportImportDropdown = () => {
  return {
    type: actionTypes.TOGGLE_EXPORT_IMPORT_DROPDOWN,
  };
};

const getAllContactsSuccess = contacts => {
  return {
    type: actionTypes.GET_ALL_CONTACTS_SUCCESS,
    contacts,
  };
};

export const getAllContacts = () => {
  return dispatch => {
    axios.get('/api/user-service/get-contacts', ).then(res => {
      dispatch(getAllContactsSuccess(res.data));
    });
  };
};

const getContactStart = () => {
  return{
    type: actionTypes.GET_CONTACT_START,
  }
}

const getContactEnd = () => {
  return{
    type: actionTypes.GET_CONTACT_END,
  }
}

export const getContact = contactId => {
  return async (dispatch) => {
    dispatch(getContactStart())
    try{
      const response = await axios.get(`/api/user-service/get-contact?contactId=${contactId}`)
      dispatch(getContactEnd());
      return response.data;
    }catch(error){
      dispatch(getContactEnd());
      console.log('error', error);
      throw error;
    }
  }
}

export const getContactsByProfileId = (profileId) => {
  return dispatch => {
    axios.get('/api/user-service/get-contacts-by-profile-name?profileId=' + profileId).then(response => {
      dispatch(getAllContactsSuccess(response.data))
    });
  };
};

export const addContact = (contact, successCallback) => {
  return () => {
    axios
      .post('/api/user-service/add-contact', contact)
      .then(() => successCallback());
  };
};

const addNewContactStart = () => {
  return {
    type: actionTypes.ADD_NEW_CONTACT_START,
  };
};

const addNewContactEnd = () => {
  return {
    type: actionTypes.ADD_NEW_CONTACT_END,
  };
};

export const changeSelectedId = (payload) => {
  return {
    type: actionTypes.CHANGE_SELECTED_ID,
    payload
  }
};

export const addNewContact = (contactData, showToast, closePopup) => {
  return dispatch => {
    dispatch(addNewContactStart());
    axios
      .post('/api/user-service/add-contact', contactData)
      .then(() => {
        showToast('Contact added successfully!');
        closePopup();
        dispatch(addNewContactEnd());
      })
      .catch(() => {
        showToast('Failed to add contacts');
        dispatch(addNewContactEnd());
      });
  };
};

const deleteContactSuccess = (contactId) => {
  return {
    type: actionTypes.DELETE_CONTACT_SUCCESS,
    contactId,
  };
};


export const deleteContact = (contactId,successCallback) => {
  return dispatch => {
    axios
      .delete(`/api/user-service/delete-contact?contactId=${contactId}`)
      .then(()=>{
        dispatch(deleteContactSuccess(contactId));
        if (successCallback)successCallback();
      }).catch((e) => {
      console.log(e);
    });
  }
}



const updateContactStart = () => {
  return {
    type: actionTypes.ADD_NEW_CONTACT_START,
  };
};

const updateContactEnd = () => {
  return {
    type: actionTypes.ADD_NEW_CONTACT_END
  };
};

export const updateContact = contactDetails => {
  return dispatch => {
    dispatch(updateContactStart());
    axios
      .put('api/user-service/update-contact', contactDetails)
      .then(() => {
        dispatch(updateContactEnd());
        dispatch(changeSelectedId(null));
      })
      .catch(() => {
        dispatch(updateContactEnd());
        dispatch(changeSelectedId(null));
      });
  };
};
