import classes from "./AccountUpdateConfirmationPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import {FiKey} from "react-icons/fi";
import {adjustColorBrightness, createGradient} from "../../../../../shared/constants";
import {RiEyeFill, RiEyeOffFill} from "react-icons/ri";

const AccountUpdateConfirmationPopup = props => {
  const [password,setPassword] = React.useState('');
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);

  const onClickFunction = () => {
    props.getPassword(password);
  }

  const viewPasswordOnClickHandler = () => {
    if (!isPasswordShown) {
      setIsPasswordShown(true);
    } else {
      setIsPasswordShown(false);
    }
  };

  return (
    <div className={classes.AccountUpdateConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>Enter your Password</p>
      <p className={classes.InfoText}>
        Updating your account details will log you out !
      </p>

      <div style={{background: adjustColorBrightness(props.color, 100)}} className={classes.MobileInputWrapper}>
        <form className={classes.MobileInput}>
          <div className={classes.IconContainer}>
            <FiKey size={16} color="#8d8d8d"/>
          </div>
          <input autoComplete='username' style={{display: 'none'}}/>
          <input
            type={isPasswordShown ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            autoComplete={'new-password'}
          />
          <div
            className={classes.ViewPassword}
            onClick={viewPasswordOnClickHandler}
          >
            {isPasswordShown ?  <RiEyeFill/> :<RiEyeOffFill/>}
          </div>
        </form>
      </div>
      <button
        style={{
          background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 50)} 100%)`
        }}
        className={classes.ContinueButton}
        onClick={onClickFunction}>
        Submit
      </button>
    </div>
  );
};

export default AccountUpdateConfirmationPopup
