import React from 'react';
import classes from './ProfileCard.module.scss';
import {BsEye} from 'react-icons/bs';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

const ProfileCard = props => {
    const navigate = useNavigate();
    const location = useLocation();

    const {profile, isActive} = props;
    const firstName = profile.data.find(dataObj => dataObj.name === 'First Name');
    const lastName = profile.data.find(dataObj => dataObj.name === 'Last Name');
    const title = profile.data.find(dataObj => dataObj.name === 'Title');
    const company = profile.data.find(dataObj => dataObj.name === 'Company');

    const onSelectProfileHandler = () => {
        navigate(`/edit-profile/${profile.id}`);
    };

    return (
        <div
            className={`${classes.ProfileCard} ${props.companyExists ? '' : classes.BackgroundImage}`}
            style={{borderColor: isActive ? props.themeColor : '#3d3d3d'}}
            onClick={onSelectProfileHandler}
        >
            <div className={classes.ProfileContainer}>
                <div className={classes.CardHeader}>
                    {location.pathname !== '/teams/profiles' && (
                        <div
                            className={classes.ViewButton}
                            onClick={event => {
                                props.showConfirmation(profile.id);
                                event.stopPropagation();
                            }}
                        >
                            <BsEye color="#A8A8A8" size={16}/>
                        </div>
                    )}
                    {location.pathname !== '/teams/profiles' && (
                        <div
                            className={isActive ? classes.StatusActive : classes.StatusInactive}
                            onClick={event => {
                                props.onChangeActiveProfileHandler(profile.id);
                                event.stopPropagation();
                            }}
                        >
                            <p>Inactive</p>
                            <p style={{
                                backgroundColor: isActive ? props.themeColor : null,
                                borderColor: isActive ? props.themeColor : null
                            }}>
                                Active
                            </p>
                        </div>
                    )}
                </div>
                <div
                    className={isActive ? classes.ProfileInfoContainerActive : classes.ProfileInfoContainerInactive}
                    style={{color: isActive ? props.themeColor : classes.ProfileInfoContainerInactive}}
                >
                    <h2>{firstName?.value} {lastName?.value}</h2>
                    <p>{title?.value}</p>
                    <span>{company?.value}</span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    userId: state.auth.user.id,
    themeColor: state.home.status.themeColor,
    companyExists: state.home.companyExists,
});

export default connect(mapStateToProps)(ProfileCard);
