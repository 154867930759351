import React from 'react';
import classes from './PrimeTabMenu.module.scss';
import {RiHome4Fill} from 'react-icons/ri';
import AnalyticsIcon from 'assets/images/primeMenu/AnalyticsSVG.svg';
import ContactsIcon from 'assets/images/primeMenu/ContactsSVG.svg';
import LinkIcon from 'assets/images/primeMenu/LinkSVG.svg';
import AccountIcon from 'assets/images/primeMenu/AccountsSVG.svg';
import TeamsIcon from 'assets/images/primeMenu/Teams.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import * as actions from 'store/actions';
import {connect} from 'react-redux';
import SVG from 'react-inlinesvg';

const menuItems = [
  { path: '/home', icon: <RiHome4Fill size={23} />, label: 'Home' },
  { path: '/analytics', icon: <SVG src={AnalyticsIcon} alt="Analytics" />, label: 'Analytics' },
  { path: '/contacts', icon: <SVG src={ContactsIcon} alt="Contacts" />, label: 'Contacts' },
  { path: '/link', icon: <SVG src={LinkIcon} alt="Link" />, label: 'Link' },
  { path: '/account', icon: <SVG src={AccountIcon} alt="Account" />, label: 'Account' },
];

const PrimeDesktopMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onNavigate = (path) => {
    navigate(path);
    if (props.dropdownVisible) {
      props.onToggleExportImportDropdown();
    }
  };

  const isActive = (path) => {
    return location.pathname === path || (path === '/teams' && location.pathname.includes('/teams'));
  };

  return (
      <div className={classes.PrimeTabMenu}>
        <div className={classes.TabOptions}>
          {menuItems.map((item) => (
              <div
                  key={item.path}
                  className={isActive(item.path) ? classes.TabOptionActive : classes.TabOptionInactive}
                  onClick={() => onNavigate(item.path)}
              >
                {React.cloneElement(item.icon, {
                  style: {fill: isActive(item.path) ? "white" : props.themeColor},
                })}
                <p style={{color: isActive(item.path) ? "white" : props.themeColor}}>{item.label}</p>
              </div>
          ))}
          {props.userRole === 'ROLE_COMPANY_ADMIN' && (
              <div
                  className={isActive('/teams') ? classes.TabOptionActive : classes.TabOptionInactive}
                  onClick={() => onNavigate('/teams')}
              >
                <SVG
                    src={TeamsIcon}
                    alt="Teams"
                    style={{fill: isActive('/teams') ? "white" : props.themeColor}}
                />
                <p style={{color: isActive('/teams') ? "white" : props.themeColor}}>Teams</p>
              </div>
          )}
        </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
  dropdownVisible: state.contact.exportImportDropdownVisible,
  themeColor: state.home.status.themeColor,
  userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleExportImportDropdown: () => dispatch(actions.toggleExportImportDropdown()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimeDesktopMenu);
