import classes from './Profiles.module.scss';
import button from "../../../../../UI/Button/Button";
import {BiSearchAlt} from "react-icons/bi";
import React, {useEffect, useState} from "react";
import ProfileCard from "../../../Home/ProfileCard/ProfileCard";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import * as actions from '../../../../../../store/actions';

const Profiles = props => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    props.onGetAllTeamsProfiles();
  }, []);

  const filteredData = props.profiles.filter((profile) => {
    const profileData = profile.data;
    const match = profileData.find((data) => {
      const value = data?.value?.toLowerCase();
      return (
        data.name.toLowerCase() === "first name" && value.includes(searchTerm.toLowerCase()) ||
        data.name.toLowerCase() === "last name" && value.includes(searchTerm.toLowerCase())
      );
    });
    return match;
  });

  return (
    <div className={classes.ProfilesWrapper}>
      <div className={classes.TopContainer}>
        <div className={classes.ProfileCount}>
          <h1>Profiles</h1>
          <span>{props.profiles.length}</span>
        </div>
        <div className={classes.SearchContainer}>
          <div>
            <BiSearchAlt/>
            <input type="text" placeholder="Search profiles..." value={searchTerm} onChange={event => setSearchTerm(event.target.value)}/>
          </div>
        </div>
      </div>

      <div className={classes.Content}>
        {props.loading ? (
          <div className={classes.SpinnerContainer}>
            <ClipLoader color={props.color} loading={true} size={60}/>
          </div>
        ) : (
          props.profiles.length === 0 ? (
            <h1 className={classes.NoData}>No data available</h1>
          ) : (
            filteredData.map(profile => (
              <ProfileCard
                showConfirmation={id => {
                  const profile = props.profiles.find(
                    profile => profile.id === id,
                  );

                  if (profile.active) {
                    window.open(
                      process.env.REACT_APP_SHAREABLE_VIEW_URL+props.user,
                      '_blank',
                    );
                  } else {
                    // Handle the case where the profile is not active
                  }
                }}
                profile={profile}
                key={profile.id}
              />
            ))
          )
        )}



        <button
          className={classes.CreateNewProfileButtonMobile}
          onClick={() => navigate('/create-profile')}
        >
          Create New Profile
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    profiles: state.teams.profiles,
    color: state.home.status.themeColor,
    loading: state.teams.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTeamsProfiles: () => dispatch(actions.getAllTeamsUserProfiles()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profiles);

