import React from 'react';
import {Select} from 'antd';
import classes from './AddFieldModal.module.scss';
import {iconList, IconPickerItem} from 'react-fa-icon-picker';
import Backdrop from '../../UI/Backdrop/Backdrop';
import {MdArrowDropDown, MdOutlineClose} from 'react-icons/md';
import {FiSearch} from 'react-icons/fi';
import {connect} from 'react-redux';
import {adjustColorBrightness, createGradient} from '../../../shared/constants';
import {PROFILE_DATA_FIELD_TYPE} from "../../../common/enums";
import {showToast} from "../../../common/utils";

const {Option} = Select;

const fieldTypeInitialState = 'Select Field Type';

const AddFieldModal = (props) => {
    const [fieldIcon, setFieldIcon] = React.useState("");
    const [fieldName, setFieldName] = React.useState("");
    const [fieldType, setFieldType] = React.useState(fieldTypeInitialState);
    const [searchText, setSearchText] = React.useState("");
    const [adjustColor, setAdjustColor] = React.useState("");

    const icons = [...iconList];

    const filteredIcons = icons.filter((icon) =>
        icon.toLowerCase().match(searchText.toLowerCase())
    );

    const addProfileFieldHandler = () => {
        if (formIsValid()) {
            if (props.profileFields.some(field => field.name === fieldName)) {
                showToast('Profile field name already exists, please try again', props.themeColor);
                return;
            }
            props.onAddProfileField({fieldName, fieldType, fieldIcon});
            setFieldIcon("");
            setFieldName("");
            setFieldType(fieldTypeInitialState);
            props.setShowModal(false);
        }
    };

    const formIsValid = () => {
        if (fieldIcon === "") {
            showToast('Please select an icon', props.themeColor);
        } else if (fieldName === "") {
            showToast('Field name is required', props.themeColor);
            return false;
        } else if (fieldType === "" || fieldType === fieldTypeInitialState) {
            showToast('Field type is required', props.themeColor);
            return false;
        } else {
            return true;
        }
    };

    React.useEffect(() => {
        setAdjustColor(adjustColorBrightness(props.themeColor, 60));
    }, []);

    return (
        <Backdrop onClick={() => props.setShowModal(false)}>
            <div className={classes.AddFieldModal}>
                <div className={classes.LeftContainer}>
                    <div className={classes.CloseButtonContainerLeft}>
                        <div onClick={() => props.setShowModal(false)}>
                            <MdOutlineClose size={20} color="#C5C5C5"/>
                        </div>
                    </div>
                    <div className={classes.SelectedIconContainer}>
                        <p>Select an Icon</p>
                    </div>

                    <div className={classes.SearchInputWrapper}>
                        <FiSearch color="#8f8f8f" size={20}/>
                        <input
                            placeholder="Search icons..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <div className={classes.IconContainer}>
                        <div>
                            {filteredIcons.map((icon) => (
                                <IconPickerItem
                                    icon={icon}
                                    size={25}
                                    color={fieldIcon === icon ? 'white' : '#939393'}
                                    key={icon}
                                    onClick={() => setFieldIcon(icon)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.RightContainer}>
                    <div className={classes.CloseButtonContainerRight}>
                        <div onClick={() => props.setShowModal(false)}>
                            <MdOutlineClose size={20} color="#C5C5C5"/>
                        </div>
                    </div>
                    <div className={classes.RightContainerContent}>
                        <div className={classes.InputContainer}>
                            <p>Add Field Data</p>
                            <div
                                style={{
                                    background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(
                                        adjustColor,
                                        100
                                    )} 100%)`,
                                }}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <input
                                        placeholder="Field Name"
                                        value={fieldName}
                                        onChange={(e) => setFieldName(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(
                                        adjustColor,
                                        100
                                    )} 100%)`,
                                }}
                                className={classes.InputWrapper}>
                                <Select
                                    suffixIcon={<MdArrowDropDown color={"#C5C5C5"} size={28}/>}
                                    placeholder={"Select Field Type"}
                                    value={fieldType}
                                    onChange={(value) => setFieldType(value)}
                                    style={{
                                        width: '100%',
                                        height: "56px",
                                        backgroundColor: "#242424",
                                        borderRadius: 8,
                                        color: "#242424",
                                        fontFamily: "inherit"
                                    }}
                                >
                                    {Object.values(PROFILE_DATA_FIELD_TYPE).filter(type => ![PROFILE_DATA_FIELD_TYPE.IMAGE, PROFILE_DATA_FIELD_TYPE.FILE].includes(type)).map((type) => (
                                        <Option key={type} value={type} style={{backgroundColor: "#242424"}}>
                                            {type
                                                .replace(/_/g, ' ')
                                                .toLowerCase()
                                                .replace(/\b\w/g, char => char.toUpperCase())}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <button
                            style={{
                                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(
                                    props.themeColor,
                                    50
                                )} 100%)`,
                            }}
                            className={classes.SaveFieldButton}
                            onClick={addProfileFieldHandler}
                        >
                            Save Field
                        </button>
                    </div>
                </div>
            </div>
        </Backdrop>
    )
        ;
};

export default connect()(AddFieldModal);
