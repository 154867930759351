import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {connect} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import classes from './App.module.scss';

import CreateOrEditProfilePage from './pages/CreateOrEditProfilePage/CreateOrEditProfilePage';
import Login from './pages/LoginPage/LoginPage';
import Register from './pages/RegisterPage/RegisterPage';
import Dashboard from './pages/Dashbord/DashboardPage';
import Home from './components/Dashboard/DashboardBody/Home/Home';
import Contacts from './components/Dashboard/DashboardBody/Contacts/Contacts';
import ShareableLink from './components/Dashboard/DashboardBody/ShareableLink/ShareableLink';
import Account from './components/Dashboard/DashboardBody/Account/Account';
import Analytics from './components/Dashboard/DashboardBody/Analytics/Analytics';
import ForgotPassword from './pages/ForgotPasswordPage/ForgotPasswordPage';
import Teams from './components/Dashboard/DashboardBody/Teams/Teams';
import Users from './components/Dashboard/DashboardBody/Teams/Body/Users/Users';
import Profiles from './components/Dashboard/DashboardBody/Teams/Body/Profiles/Profiles';
import CompanyDetails from './components/Dashboard/DashboardBody/Teams/Body/CompanyDetails/CompanyDetails';
import Branding from './components/Dashboard/DashboardBody/Teams/Body/Branding/Branding';
import Settings from './components/Dashboard/DashboardBody/Teams/Body/Setttings/Settings';

import {signOut, tryAutoSignIn} from './store/actions';

const App = ({token, role, onTryAutoSignIn, onSignOut}) => {
  // useEffect(() => {
  // Google APIs should load only in production
  //   if (process.env.NODE_ENV === 'production') {
  //     const loadGapiClient = () => {
  //       window.gapi.load('client', () => {
  //         window.gapi.client.init({
  //           apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //           discoveryDocs: [process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS],
  //         });
  //       });
  //     };
  //
  //     const loadGoogleAccounts = () => {
  //       window.tokenClient = window.google.accounts.oauth2.initTokenClient({
  //         client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //         scope: process.env.REACT_APP_GOOGLE_SCOPES,
  //         callback: '',
  //       });
  //     };
  //
  //     const script1 = document.createElement('script');
  //     script1.src = 'https://apis.google.com/js/api.js';
  //     script1.async = true;
  //     script1.defer = true;
  //     script1.onload = loadGapiClient;
  //     document.body.appendChild(script1);
  //
  //     const script2 = document.createElement('script');
  //     script2.src = 'https://accounts.google.com/gsi/client';
  //     script2.async = true;
  //     script2.defer = true;
  //     script2.onload = loadGoogleAccounts;
  //     document.body.appendChild(script2);
  //
  //     return () => {
  //       document.body.removeChild(script1);
  //       document.body.removeChild(script2);
  //     };
  //   }
  // }, []);

  useEffect(() => {
    const twoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;
    if (localStorage.getItem('lastActive') < twoDaysAgo) {
      onSignOut();
    }
    onTryAutoSignIn();
  }, [onSignOut, onTryAutoSignIn]);

  useEffect(() => {
    const setLastActive = () => localStorage.setItem('lastActive', Date.now());

    const handleBeforeUnload = setLastActive;
    const handleVisibilityChange = () => {
      if (document.hidden) setLastActive();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    setLastActive();

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className={classes.App}>
      <ToastContainer />
      <Routes>
        {!token ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<Navigate to="home" replace />} />
              <Route path="home" element={<Home />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="link" element={<ShareableLink />} />
              <Route path="account" element={<Account />} />

              {role === 'ROLE_COMPANY_ADMIN' && (
                <Route path="teams" element={<Teams />}>
                  <Route index element={<Users />} />
                  <Route path="profiles" element={<Profiles />} />
                  <Route
                    path="create-profile"
                    element={<CreateOrEditProfilePage mode="create" />}
                  />
                  <Route path="company-details" element={<CompanyDetails />} />
                  <Route path="branding" element={<Branding />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="*" element={<Navigate to="/teams" replace />} />
                </Route>
              )}

            </Route>
            <Route
              path="create-profile"
              element={<CreateOrEditProfilePage mode="create" />}
            />
            <Route
              path="edit-profile/:profileId"
              element={<CreateOrEditProfilePage mode="edit" />}
            />
            {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          </>
        )}
      </Routes>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  role: state.auth.userRole,
});

const mapDispatchToProps = dispatch => ({
  onTryAutoSignIn: () => dispatch(tryAutoSignIn()),
  onSignOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
